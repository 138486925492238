<template>
  <div
    :id="widgetId"
    :style="{
      height: this.widgetHeight + 'px',
      width: this.widgetWidth + 'px',
      position: 'relative',
      overflow: 'hidden',
      paddingTop: '2px',
      background: darkDark !== 'white' ? this.cardBackground : '#FFFFF7',
      color: 'var(--textColor)',
      'border-radius': '10px',
    }"
    @contextmenu="setContextIdWidget"
    class="swaborsurge"
  >
    <div
      class="h-full w-full"
      :style="{
        background: darkDark !== 'white' ? this.cardBackground : '#FFFFF7',
      }"
    >
      <SwabSurgeNavBar
        :wellId="wellId"
        :wellboreId="wellboreId"
        :showAll="zoomOut"
        :onToggleSideBar="onToggleSideBar"
        :onChangeValues="onChangeValues"
        :onSave="onSave"
        :displayId="displayId"
        :widgetId="widgetId"
        :isSaved="isSaved"
      />
      <div class="flex overflow-hidden">
        <div
          class="flex-item flex_1 sidebar_cntr shadow-xl"
          :style="{
            background: darkDark
              ? gridBackground || 'var(--navBar2Bg)'
              : gridBackground || 'var(--widgetBg)',
          }"
        >
          <SwabSurgeLeftbar
            :wellId="wellId"
            :wellboreId="wellboreId"
            :sideBarForProp="sideBarForProp"
            :toggleSidebarProp="toggleSidebar"
            :assemblySelected="renderGraph"
            :torHklToggle="torHklToggle"
            :rtToggle="rtToggle"
            :isRealtime="isRealtime"
            :isSaved="isSaved"
            :listCount="listCount"
            :bhaSelected="bhaSelected"
            :fmChange="fmChange"
            :planvsrt="planvsrt"
            :planvsreal="planvsreal"
            :zoomOnOff="zoomOnOff"
            :planId="planId"
            :bhaId="bhaId"
            :bhaName="bhaName"
            :selectedWellInfo="selectedWellInfo"
            :hklOrTor="hklOrTor != 'TORQUE'"
            :selectedSavedRun="selectedSavedRun"
            :isZoomFeature="isZoomFeature"
            :toUpdate_ss_save_list="toUpdate_ss_save_list"
            :swab_surge_save_data="swab_surge_save_data"
            :swab_surge_trigger_data="swab_surge_trigger_data"
            @change_toUpdate_ss_save_list="change_toUpdate_ss_save_list"
            @ss_trigger_list_click="ss_trigger_list_click"
          />
        </div>
        <div class="w-2/6">
          <div class="flex pr-2">
            <div class="w-1/2">
              <svg
                height="240"
                width="150"
                style="padding-top: 20px; padding-left: 20px"
              >
                <g>
                  <path
                    fill="none"
                    :stroke="darkDark !== 'white' ? '#fff' : '#aaa'"
                    stroke-width="1.5px"
                    d="M 10 35 l 0 80"
                  ></path>
                </g>
                <g>
                  <path
                    fill="none"
                    :stroke="darkDark !== 'white' ? '#fff' : '#aaa'"
                    stroke-width="1.5px"
                    d="M 10 115 l 94 74"
                  ></path>
                </g>
                <g>
                  <path
                    fill="#aaa"
                    :stroke="darkDark !== 'white' ? '#fff' : '#aaa'"
                    stroke-width="1.5px"
                    d="M 18 25 l 0 80 l 7 0 l 0 -80 l -7 0"
                  ></path>
                </g>
                <g>
                  <path
                    fill="#aaa"
                    :stroke="darkDark !== 'white' ? '#fff' : '#aaa'"
                    stroke-width="1.5px"
                    d="M 188 47 l 0 20 l 12 0 l 0 -20 l -12 0 "
                    style="transform: rotate(42deg); border-radius: 20px"
                    stroke-linejoin="round"
                  ></path>
                </g>
                <g>
                  <path
                    fill="#aaa"
                    :stroke="darkDark !== 'white' ? '#fff' : '#aaa'"
                    stroke-width="1.5px"
                    d="M 175 50 l 0 14 l 12 0 l 0 -14 l -12 0 "
                    style="transform: rotate(42deg); border-radius: 20px"
                    ry="35"
                    stroke-linejoin="round"
                  ></path>
                </g>
                <g>
                  <path
                    fill="#aaa"
                    :stroke="darkDark !== 'white' ? '#fff' : '#aaa'"
                    stroke-width="1.5px"
                    d="M -73 80 l 00 90 l 7 0 l 0 -90 l -7 0"
                    style="transform: rotate(-52deg)"
                  ></path>
                </g>
                <g>
                  <path
                    fill="none"
                    :stroke="darkDark !== 'white' ? '#fff' : '#aaa'"
                    stroke-width="1.5px"
                    d="M 15 13 a 7, 7 0 1, 0 14, 0 a 7, 7 0 1, 0 -14, 0 "
                  ></path>
                </g>
                <g>
                  <path
                    fill="none"
                    :stroke="darkDark !== 'white' ? '#fff' : '#aaa'"
                    stroke-width="1.5px"
                    d="M 22 19 l 0 6"
                  ></path>
                </g>
                <g>
                  <path
                    fill="none"
                    :stroke="darkDark !== 'white' ? '#fff' : '#aaa'"
                    stroke-width="1.5px"
                    d="M 35 35 l 0 15"
                  ></path>
                </g>
                <g>
                  <path
                    fill="none"
                    :stroke="darkDark !== 'white' ? '#fff' : '#aaa'"
                    stroke-width="1.5px"
                    d="M 35 57 l 0 40"
                  ></path>
                </g>
                <g>
                  <path
                    fill="none"
                    :stroke="darkDark !== 'white' ? '#fff' : '#aaa'"
                    stroke-width="1.5px"
                    d="M 35 50 l 15 0 "
                  ></path>
                </g>
                <g>
                  <path
                    fill="none"
                    :stroke="darkDark !== 'white' ? '#fff' : '#aaa'"
                    stroke-width="1.5px"
                    d="M 35 57 l 22 0 "
                  ></path>
                </g>
                <g>
                  <path
                    fill="none"
                    :stroke="darkDark !== 'white' ? '#fff' : '#aaa'"
                    stroke-width="1.5px"
                    d="M 50 30 l 8 0"
                  ></path>
                </g>
                <g>
                  <path
                    fill="none"
                    :stroke="darkDark !== 'white' ? '#fff' : '#aaa'"
                    stroke-width="1.5px"
                    d="M 35 97 l 89 70"
                  ></path>
                </g>
                <g>
                  <path
                    fill="none"
                    :stroke="darkDark !== 'white' ? '#fff' : '#aaa'"
                    stroke-width="1.5px"
                    d="M 50 30 l 0 20 "
                  ></path>
                </g>
                <g>
                  <path
                    fill="none"
                    :stroke="darkDark !== 'white' ? '#fff' : '#aaa'"
                    stroke-width="1.5px"
                    d="M 58 30 l 0 28 "
                  ></path>
                </g>
                <g>
                  <path
                    fill="none"
                    :stroke="darkDark !== 'white' ? '#fff' : '#aaa'"
                    stroke-width="1.5px"
                    d="M 125 167 l -21 23"
                  ></path>
                </g>
                <g>
                  <path
                    fill="none"
                    :stroke="darkDark !== 'white' ? '#fff' : '#aaa'"
                    stroke-width="1.5px"
                    d="M 47 23 a 7, 7 0 1, 0 14, 0 a 7, 7 0 1, 0 -14, 0 "
                  ></path>
                </g>
                <g>
                  <path
                    fill="#aaa"
                    stroke=""
                    stroke-width=""
                    d="M 19 100 l 0 6 l 6 0 l 0 -6 l -6 0"
                  ></path>
                </g>
              </svg>
              <div class="flex justify-center relative" style="top: -20px">
                <vertical-bar-widget
                  v-if="rect.name == 'vertical-bar'"
                  :id="rect.widgetId"
                  :displayId="displayId"
                  :widgetId="widgetId + 'swabsurge_gauge_1'"
                  :widgetHeight="rect.height"
                  :widgetWidth="rect.width"
                  :title="rect.title"
                  :fullName="rect.fullName"
                  :unit="rect.unit"
                  :autoFit="rect.autoFit"
                  :width="rect.width"
                  :height="rect.height * 1.05"
                  :barWidth="rect.width - 60"
                  :barHeight="rect.height - rect.height * 0.3"
                  :barValue="rect.barValue"
                  :rangeStart="rect.rangeStart"
                  :alertColor="rect.alertColor"
                  :blinkAlert="rect.blinkAlert"
                  :alertStartRange="rect.alertStartRange"
                  :alertEndRange="rect.alertEndRange"
                  :rangeEnd="rect.rangeEnd"
                  :interval="rect.interval"
                  :borderColor="rect.borderColor"
                  :borderWidth="rect.borderWidth"
                  :borderDisplay="rect.borderDisplay"
                  :titleColor="rect.titleColor"
                  :valueColor="rect.valueColor"
                  :unitColor="rect.unitColor"
                  :unitDisplay="rect.unitDisplay"
                  :titleDisplay="rect.titleDisplay"
                  :titleSize="rect.titleSize"
                  :valueSize="rect.valueSize"
                  :unitSize="rect.unitSize"
                  :valueDisplay="rect.valueDisplay"
                  :decimalValue="rect.decimalValue"
                  :cardBackground="'black'"
                  :verticalcardBG="rect.verticalcardBG"
                  :isChanged="rect.isChanged"
                  :barBreakPoints="rect.breakPoints"
                  :unit_conversion_factor="rect.unit_conversion_factor"
                  v-bind:class="['flex']"
                  v-on:dblclick="
                    this.$emit('to_openProperty', rect_index, rect)
                  "
                  style="
                    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
                      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
                  "
                ></vertical-bar-widget>
                <vertical-bar-widget
                  v-if="rect2.name == 'vertical-bar'"
                  :id="rect2.widgetId"
                  :displayId="displayId"
                  :widgetId="widgetId + 'swabsurge_gauge_2'"
                  :widgetHeight="rect2.height"
                  :widgetWidth="rect2.width"
                  :title="rect2.title"
                  :fullName="rect2.fullName"
                  :unit="rect2.unit"
                  :autoFit="rect2.autoFit"
                  :width="rect2.width"
                  :height="rect2.height * 1.05"
                  :barWidth="rect2.width - 60"
                  :barHeight="rect2.height - rect.height * 0.3"
                  :barValue="rect2.barValue"
                  :rangeStart="rect2.rangeStart"
                  :alertColor="rect2.alertColor"
                  :blinkAlert="rect2.blinkAlert"
                  :alertStartRange="rect2.alertStartRange"
                  :alertEndRange="rect2.alertEndRange"
                  :rangeEnd="rect2.rangeEnd"
                  :interval="rect2.interval"
                  :borderColor="rect2.borderColor"
                  :borderWidth="rect2.borderWidth"
                  :borderDisplay="rect2.borderDisplay"
                  :titleColor="rect2.titleColor"
                  :valueColor="rect2.valueColor"
                  :unitColor="rect2.unitColor"
                  :unitDisplay="rect2.unitDisplay"
                  :titleDisplay="rect2.titleDisplay"
                  :titleSize="rect2.titleSize"
                  :valueSize="rect2.valueSize"
                  :unitSize="rect2.unitSize"
                  :valueDisplay="rect2.valueDisplay"
                  :decimalValue="rect2.decimalValue"
                  :cardBackground="'black'"
                  :verticalcardBG="rect2.verticalcardBG"
                  :isChanged="rect2.isChanged"
                  :barBreakPoints="rect2.breakPoints"
                  :unit_conversion_factor="rect2.unit_conversion_factor"
                  v-bind:class="['flex ml-2']"
                  v-on:dblclick="
                    this.$emit('to_openProperty', rect_index, rect2)
                  "
                  style="
                    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
                      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
                  "
                ></vertical-bar-widget>
              </div>
            </div>
            <div class="w-1/2 mr-2">
              <div class="pb-1 pt-1 selectionCntnr">
                <h4 class="font-semibold py-1 text-xxs">Trip Mode</h4>
                <div class="mb-2 pl-3 flex justify-between items-center">
                  <label class="block text-xxxs w-1/2 font-medium" for="">
                    Select Mode
                  </label>
                  <select
                    @change="tripmode($event, 'selected_tripmode')"
                    v-model="selected_trip_mode"
                    class="w-1/2 selcs rounded text-xxxs text-white focus:outline-none focus-visible:outline-none"
                    :style="{
                      background:
                        darkDark !== 'white' ? this.cardBackground : '#FFFFF7',
                      color: darkDark !== 'white' ? '#fff' : '#222',
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }"
                  >
                    <option value="" disabled>Select</option>
                    <option
                      :key="option.text"
                      v-for="option in options"
                      :value="option.text.replace(/\s/g, '')"
                    >
                      {{ option.text }}
                    </option>
                  </select>
                </div>
                <div class="mb-2 pl-3 pr-2 flex justify-between items-center">
                  <label class="block text-xxxs w-1/2 font-medium" for="">
                    Trip Status
                  </label>
                  <select
                    @change="tripstatus($event, 'selected_tripstatus')"
                    v-model="selected_trip_statues"
                    class="w-1/2 selcs rounded text-xxxs text-white focus:outline-none focus-visible:outline-none"
                    :style="{
                      background:
                        darkDark !== 'white' ? this.cardBackground : '#FFFFF7',
                      color: darkDark !== 'white' ? '#fff' : '#222',
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }"
                  >
                    <option value="">Select</option>
                    <option
                      :key="tripstatus.text"
                      v-for="tripstatus in tripstatuscod"
                      :value="tripstatus.text"
                    >
                      {{ tripstatus.text }}
                    </option>
                  </select>
                </div>
                <div class="mb-2 pl-3 pr-2 flex justify-between items-center">
                  <label class="block text-xxxs w-1/2 font-medium" for="">
                    Mud Weight 
                    ({{ conversionFactors?.mud_density?.unit && conversionFactors?.mud_density?.unit!=''?conversionFactors.mud_density.unit: 'lb/gal'}})
                    </label
                  >
                  <input
                    class="wid w-1/2 shadow appearance-none border rounded text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                    type="text"
                    @input="change_muddensity($event, 'mud_wght')"
                    v-model="mud_density"
                    :style="{
                      background:
                        darkDark !== 'white' ? this.cardBackground : '#FFFFF7',
                      color: darkDark !== 'white' ? '#fff' : '#222',
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }"
                  />
                </div>
                <div class="mb-2 pl-3 pr-2 flex flex-col">
                  <div class="flex justify-center items-center">
                    <label
                    class="block text-xxxs  font-medium"
                    for=""
                    style="padding-right: 5px"
                    >
                      Avg Pipe Length </label
                    >
                  </div>
                  <div class="flex justify-between items-center mt-1">
                    <div class="flex flex-col">
                      <p class="mud_weight_value">Value</p>
                      <input
                    class="wid w-1/2 shadow appearance-none border rounded text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                    type="text"
                    @change="pipelengchange"
                    v-model.number="pipe_length"
                    :style="{
                      background:
                        darkDark !== 'white' ? this.cardBackground : '#FFFFF7',
                      color: darkDark !== 'white' ? '#fff' : '#222',
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }"
                  />
                    </div>

                  <div class="unit_select_treep_speed">
                    <UnitSelect :isMetrics="isWellMetrics" :display-id="displayId" :widget-id="widgetId" :mnemonic="'dmea'" :pt="'pt-0'" :category="'Length'" :is-custom-events="true" :selected-val="conversionFactors?.average_pipe_length?.unit && conversionFactors?.average_pipe_length?.unit!=''?conversionFactors.average_pipe_length.unit: isWellMetrics? manualGraphsUnits?.average_pipe_length?.metric : manualGraphsUnits?.average_pipe_length?.imperial" :standaredUnit="manualGraphsUnits?.average_pipe_length?.standardUnits" :reverse="true"  @change_unit_handler="(val)=>changeUnitHandler('average_pipe_length',val)" :key="unitRefreshKey"/>
                  </div>
                  </div>

                </div>
                <h4 class="font-semibold pb-1 text-xxs flex justify-between items-center">
                  <div class="ml-3">Trip Speed</div>
                  <div class="unit_select_treep_speed">
                    <UnitSelect :isMetrics="isWellMetrics" :display-id="displayId" :widget-id="widgetId" :mnemonic="'dmea'" :pt="'pt-0'" :category="'Speed'" :is-custom-events="true" :selected-val="conversionFactors?.tripp_speed?.unit && conversionFactors?.tripp_speed?.unit!=''?conversionFactors.tripp_speed.unit: isWellMetrics? manualGraphsUnits?.tripp_speed?.metric : manualGraphsUnits?.tripp_speed?.imperial" :standaredUnit="manualGraphsUnits?.tripp_speed?.standardUnits" :reverse="true" @change_unit_handler="(val)=>changeUnitHandler('tripp_speed',val)" :key="unitRefreshKey"/>
                  </div>
                </h4>
                <div class="mb-2 pl-3 flex justify-between items-center">
                  <label class="block text-xxxs w-1/2 font-medium" for="">
                    Min </label
                  >
                  <input
                    class="wid w-1/2 shadow appearance-none border rounded text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                    type="text"
                    @change="speedMin"
                    v-model="speed_min"
                    :style="{
                      background:
                        darkDark !== 'white' ? this.cardBackground : '#FFFFF7',
                      color: darkDark !== 'white' ? '#fff' : '#222',
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }"
                  />
                </div>
                <div class="mb-2 pl-3 flex justify-between items-center">
                  <label class="block text-xxxs w-1/2 font-medium" for="">
                    Max </label
                  >
                  <input
                    class="wid w-1/2 shadow appearance-none border rounded text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                    type="text"
                    @change="speedMax"
                    v-model="speed_max"
                    :style="{
                      background:
                        darkDark !== 'white' ? this.cardBackground : '#FFFFF7',
                      color: darkDark !== 'white' ? '#fff' : '#222',
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }"
                  />
                </div>
                <h4 class="font-semibold py-1 text-xxs">Stand Speed</h4>
                <div class="mb-2 pl-3 flex justify-between items-center">
                  <label class="block text-xxxs w-1/2 font-medium" for="">
                    Min (Stand/min)</label
                  >
                  <input
                    class="wid w-1/2 shadow appearance-none border rounded text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100 opacity-75"
                    type="text"
                    v-model="stand_speed_min"
                    :style="{
                      background:
                        darkDark !== 'white' ? this.cardBackground : '#FFFFF7',
                      color: darkDark !== 'white' ? '#fff' : '#222',
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }"
                    disabled
                  />
                </div>
                <div class="mb-2 pl-3 flex justify-between items-center">
                  <label class="block text-xxxs w-1/2 font-medium" for="">
                    Max (Stand/min)</label
                  >
                  <input
                    class="wid w-1/2 shadow appearance-none border rounded text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100 opacity-75"
                    type="text"
                    v-model="stand_speed_max"
                    :style="{
                      background:
                        darkDark !== 'white' ? this.cardBackground : '#FFFFF7',
                      color: darkDark !== 'white' ? '#fff' : '#222',
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }"
                    disabled
                  />
                </div>
              </div>

              <h4 class="font-semibold py-1 text-xxs">Features</h4>
              <div class="flex">
                <label
                  for="td_zoomOnOff"
                  class="btns toggle_cntr"
                  :class="[
                    'font-bold',
                    'text-left',
                    'w-11/12',
                    'rounded',
                    'td_btn',
                    'flex',
                    'mt-2',
                  ]"
                >
                  <div class="flex w-full items-center">
                    <div class="text-sx">ZOOM IN / OUT</div>
                    <input
                      type="checkbox"
                      class="checkbox hidden"
                      :id="'td_zoomOnOff' + this.widgetId"
                      @click="zoom_toggle()"
                      :checked="zoom_checked"
                      :style="{
                        background:
                          darkDark !== 'white'
                            ? this.cardBackground
                            : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                      }"
                    />
                    <label
                      :for="'td_zoomOnOff' + this.widgetId"
                      class="checkbox-label td_checkbox_zoom"
                      style="margin-left: auto"
                    >
                      <span class="ball"></span>
                    </label>
                  </div>
                </label>
              </div>
              <div class="flex hidden">
                <label
                  for="td_zoomOut"
                  class="btns toggle_cntr"
                  :class="[
                    'font-bold',
                    'text-left',
                    'w-11/12',
                    'rounded',
                    'td_btn',
                    'flex',
                    'mt-2',
                  ]"
                >
                  <div class="flex w-full items-center">
                    <div class="text-sx">ZOOM OUT</div>
                    <input
                      type="checkbox"
                      class="checkbox hidden"
                      id="td_zoomOut"
                      @click="zoomOut()"
                      :checked="zoom_checked"
                      :style="{
                        background:
                          darkDark !== 'white'
                            ? this.cardBackground
                            : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                      }"
                    />
                    <label
                      for="td_zoomOut"
                      class="checkbox-label td_checkbox_zoom"
                      style="margin-left: auto"
                    >
                      <span class="ball"></span>
                    </label>
                  </div>
                </label>
              </div>
              <!-- <div class="flex">
                <label
                  for="td_showAll"
                  class="btns toggle_cntr"
                  :class="[
                    'font-bold',
                    'text-left',
                    'w-11/12',
                    'rounded',
                    'td_btn',
                    'flex',
                    'mt-2',
                  ]"
                >
                  <div class="flex w-full items-center">
                    <div class="text-sx uppercase">SHOW ALL</div>
                    <input
                      type="checkbox"
                      class="checkbox hidden"
                      :id="'td_showAll' + this.widgetId"
                      @click="showAll()"
                      :ref="'back_to_original' + this.widgetId"
                      :style="{
                        background:
                          darkDark !== 'white'
                            ? this.cardBackground
                            : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                      }"
                    />
                    <label
                      :for="'td_showAll' + this.widgetId"
                      class="checkbox-label td_checkbox_zoom"
                      style="margin-left: auto"
                    >
                      <span class="ball"></span>
                    </label>
                  </div>
                </label>
              </div> -->
              <div class="flex">
                <label
                  for="td_tooltip"
                  class="btns toggle_cntr"
                  :class="[
                    'font-bold',
                    'text-left',
                    'w-11/12',
                    'rounded',
                    'td_btn',
                    'flex',
                    'mt-2',
                  ]"
                >
                  <div class="flex w-full items-center">
                    <div class="text-sx">TOOL TIP</div>
                    <input
                      type="checkbox"
                      class="checkbox hidden"
                      :id="'td_tooltip' + this.widgetId"
                      @click="tooltipOn()"
                      :checked="isShow"
                      :style="{
                        background:
                          darkDark !== 'white'
                            ? this.cardBackground
                            : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                      }"
                    />
                    <label
                      :for="'td_tooltip' + this.widgetId"
                      class="checkbox-label td_checkbox_zoom"
                      style="margin-left: auto"
                    >
                      <span class="ball"></span>
                    </label>
                  </div>
                </label>
              </div>
              <div class="flex justify-between">
                <label class="mr-6">
                  <button
                    class="curser text-white active:bg-green-800 text-sx font-bold button uppercase px-3 transition-all py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear duration-150 blues"
                    type="button"
                    v-on:click="print()"
                  >
                    Print
                  </button>
                </label>
                <label class="ml-3">
                  <button
                    class="curser text-white active:bg-green-800 text-sx font-bold button uppercase px-3 mr-1 mb-1 ease-linear transition-all duration-150 greens py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none"
                    type="button"
                    @click="get3DGraphData()"
                  >
                    Save
                  </button>
                </label>
              </div>
            </div>
          </div>
          <!-- modal for confirm box to capture screen starts -->
          <div class="sendMail_modal h-full" v-show="show_print_popup">
            <div class="Child_Of_sendMail_modal">
              <div
                class="p-4 py-2 flex justify-between items-center shadow"
                style="height: 42px"
              >
                <h4 class="text-xs uppercase font-semibold">
                  Download Screenshot
                </h4>
                <button
                  v-on:click="close"
                  class="text-sm font-normal uppercase text-red_"
                  style="color: #cf634b"
                >
                  <i class="fas fa-times"></i>
                </button>
              </div>
              <div
                class="w-full flex px-5 py-3 overflow-auto"
                style="height: calc(100% - 50px)"
              >
                <div class="w-full h-full flex mx-auto">
                  <canvas
                    :id="'canvas_img' + this.widgetId"
                    class="hidden"
                  ></canvas>
                  <img
                    :src="capturedImage"
                    v-if="capturedImage"
                    class="w-3/4 mx-auto"
                    style="height: fit-content; max-height: 100%"
                  />
                  <div
                    class="w-1/4 mb-2 px-4 pt-3 flex justify-end items-start"
                  >
                    <a
                      :id="'download_screenshot' + this.widgetId"
                      href=""
                      download
                    ></a>
                    <button
                      v-on:click="download"
                      class="text-xxs mr-5 font-semibold px-4 py-1 border"
                      style="
                        color: var(--activeTextColor);
                        border-color: currentColor;
                      "
                    >
                      Download
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- modal for confirm box to capture screen ends -->
          <!-- tool tip for real time graph starts -->
          <div
            v-show="real_time_tool_tip"
            ref="real_time_graph"
            class="real_time_tool_tip_popup text-xs p-2"
            :style="{
              top: mouse_y + 15 + 'px',
              left: mouse_x + 'px',
            }"
          >
            <div class="font-semibold flex flex-col" style="gap: 3px">
              <div>
                Hole Depth (ft):
                <span style="color: var(--activeTextColor)">{{
                  parseFloat(real_time_hole_depth)?.toFixed(2)
                }}</span>
              </div>
              <div>
                Bit Depth (ft):
                <span style="color: var(--activeTextColor)">{{
                  parseFloat(real_time_bit_depth)?.toFixed(2)
                }}</span>
              </div>
              <div>
                Trip Speed (ft/min) :
                <span style="color: var(--activeTextColor)">{{
                  parseFloat(real_time_trip_speed)?.toFixed(2)
                }}</span>
              </div>
              <div v-if="!realTime_psi_ppg && real_time_trip_status == 'TIH'">
                Surge Pressure (psi) :
                <span style="color: var(--activeTextColor)">{{
                  parseFloat(real_time_surge_pressure)?.toFixed(2)
                }}</span>
              </div>
              <div v-if="!realTime_psi_ppg && real_time_trip_status == 'TOOH'">
                Swab Pressure (psi):
                <span style="color: var(--activeTextColor)">{{
                  parseFloat(real_time_swab_pressure)?.toFixed(2)
                }}</span>
              </div>
              <div v-if="realTime_psi_ppg && real_time_trip_status == 'TIH'">
                Surge EMW (ppg):
                <span style="color: var(--activeTextColor)">{{
                  parseFloat(real_time_surge_pressure_in_ppg)?.toFixed(2)
                }}</span>
              </div>
              <div v-if="realTime_psi_ppg && real_time_trip_status == 'TOOH'">
                Swab EMW (ppg):
                <span style="color: var(--activeTextColor)">{{
                  parseFloat(real_time_swab_pressure_in_ppg)?.toFixed(2)
                }}</span>
              </div>
            </div>
          </div>
          <!-- tool tip for real time graph ends -->
          <!-- tool tip for static graph 1 starts -->
          <div
            v-show="static_graph_1_tool_tip"
            ref="static_graph_1"
            class="real_time_tool_tip_popup text-xs p-2"
            :style="{
              top: mouse_y + 15 + 'px',
              left: mouse_x + 'px',
            }"
          >
            <div class="font-semibold flex flex-col" style="gap: 3px">
              <div>
                Trip Speed ({{ conversionFactors?.tripp_speed?.unit && conversionFactors?.tripp_speed?.unit!="" ?
                    conversionFactors.tripp_speed.unit : "ft/min"
                }}):
                <span style="color: var(--activeTextColor)">{{
                  parseFloat(static_graph_1_speed)?.toFixed(2)
                }}</span>
              </div>
              <!-- <div>Bit Depth (ft): <span style="color: var(--activeTextColor)">{{ static_graph_1_bit_depth}}</span></div> -->
              <div>
                Trip Depth ({{ conversionFactors?.trip_depth?.unit && conversionFactors?.trip_depth?.unit!="" ?
                    conversionFactors.trip_depth.unit : "ft"
                }}):
                <span style="color: var(--activeTextColor)">{{
                  parseFloat(static_graph_1_trip_depth)?.toFixed(2)
                }}</span>
              </div>
              <div>
                Trip Speed Min ({{ conversionFactors?.tripp_speed?.unit && conversionFactors?.tripp_speed?.unit!="" ?
                    conversionFactors.tripp_speed.unit : "ft/min"
                }}):
                <span style="color: var(--activeTextColor)">{{
                  parseFloat(static_graph_1_trip_speed_min)?.toFixed(2)
                }}</span>
              </div>
              <div>
                Trip Speed Max ({{ conversionFactors?.tripp_speed?.unit && conversionFactors?.tripp_speed?.unit!="" ?
                    conversionFactors.tripp_speed.unit : "ft/min"
                }}):
                <span style="color: var(--activeTextColor)">{{
                  parseFloat(static_graph_1_trip_speed_max)?.toFixed(2)
                }}</span>
              </div>
              <div v-if="!this.$refs['theCheckbox1' + this.widgetId]?.checked">
                {{
                  selected_trip_statues?.toUpperCase() == "TIH"
                    ? "Surge"
                    : "Swab"
                }}
                Pressure (psi):
                <span style="color: var(--activeTextColor)">{{
                  parseFloat(static_graph_1_swabAndSurgePressure)?.toFixed(2)
                }}</span>
              </div>
              <div v-if="this.$refs['theCheckbox1' + this.widgetId]?.checked">
                {{
                  selected_trip_statues?.toUpperCase() == "TIH"
                    ? "Surge"
                    : "Swab"
                }}
                EMW ({{ conversionFactors?.mud_density?.unit && conversionFactors?.mud_density?.unit!="" ?
                    conversionFactors.mud_density.unit : "ppg"
                }}):
                <span style="color: var(--activeTextColor)">{{
                  parseFloat(static_graph_1_swabAndSurgePressureinppg)?.toFixed(
                    2
                  )
                }}</span>
              </div>
            </div>
          </div>
          <!-- tool tip for static graph 1 ends -->
          <!-- tool tip for static graph 2 starts -->
          <div
            v-show="static_graph_2_tool_tip"
            ref="static_graph_2"
            class="real_time_tool_tip_popup text-xs p-2"
            :style="{
              top: mouse_y + 15 + 'px',
              left: mouse_x + 'px',
            }"
          >
            <div class="font-semibold flex flex-col" style="gap: 3px">
              <div>
                Trip Speed ({{ conversionFactors?.tripp_speed?.unit && conversionFactors?.tripp_speed?.unit!="" ?
                    conversionFactors.tripp_speed.unit : "ft/min"
                }}):
                <span style="color: var(--activeTextColor)">{{
                  parseFloat(static_graph_2_trip_speed)?.toFixed(2)
                }}</span>
              </div>
              <div>
                Trip Depth ({{ conversionFactors?.trip_depth?.unit && conversionFactors?.trip_depth?.unit!="" ?
                    conversionFactors.trip_depth.unit : "ft"
                }}):
                <span style="color: var(--activeTextColor)">{{
                  parseFloat(static_graph_2_trip_depth)?.toFixed(2)
                }}</span>
              </div>
              <div>
                Bit Depth ({{ conversionFactors?.trip_depth?.unit && conversionFactors?.trip_depth?.unit!="" ?
                    conversionFactors.trip_depth.unit : "ft"
                }}):
                <span style="color: var(--activeTextColor)">{{
                  parseFloat(static_graph_2_bit_depth)?.toFixed(2)
                }}</span>
              </div>
              <div v-if="!this.$refs['theCheckbox1' + this.widgetId]?.checked">
                {{
                  selected_trip_statues?.toUpperCase() == "TIH"
                    ? "Surge"
                    : "Swab"
                }}
                Pressure (psi):
                <span style="color: var(--activeTextColor)">{{
                  parseFloat(static_graph_2_swabAndSurgePressure)?.toFixed(2)
                }}</span>
              </div>
              <div v-if="this.$refs['theCheckbox1' + this.widgetId]?.checked">
                {{
                  selected_trip_statues?.toUpperCase() == "TIH"
                    ? "Surge"
                    : "Swab"
                }}
                EMW ({{ conversionFactors?.mud_density?.unit && conversionFactors?.mud_density?.unit!="" ?
                    conversionFactors.mud_density.unit : "ppg"
                }}):
                <span style="color: var(--activeTextColor)">{{
                  parseFloat(static_graph_2_swabAndSurgePressureinppg)?.toFixed(
                    2
                  )
                }}</span>
              </div>
            </div>
          </div>
          <!-- tool tip for static graph 2 ends -->
        </div>
        <div class="w-4/6">
          <div class="flex">
            <!-- Garph -->
            <div class="w-1/3">
              <div
                class="flex_items relative"
                v-on:dblclick="dblClick('tvdTrajectoryTrend' + this.widgetId)"
              >
                <div
                  class="graph_line_indicator_div flex items-end"
                  :class="
                    static_graph_1_indicators.length
                      ? 'justify-between'
                      : 'justify-center'
                  "
                >
                  <span
                    class="hdng uppercase font-bold relative"
                    :style="{
                      left: static_graph_1_indicators.length ? '55px' : '25px',
                    }"
                    >Manual graph</span
                  >
                  <div v-if="static_graph_1_indicators.length">
                    <button class="info_btn flex mr-3 ml-auto pt-1">
                      <i class="fas fa-info-circle"></i>
                    </button>
                    <div class="info_popup p-2 absolute right-0 hidden">
                      <div
                        v-for="(obj, index) of static_graph_1_indicators"
                        :key="index"
                        class="pb-1 flex items-center text-xs"
                      >
                        <span
                          style="width: 30px; height: 2px"
                          :style="{ background: obj.color }"
                        ></span>
                        <span class="pl-2 font-semibold"
                          >Trip Depth
                          {{ Number(obj.value).toFixed(2) }} ({{ conversionFactors?.trip_depth?.unit && conversionFactors?.trip_depth?.unit!="" ?
                    conversionFactors.trip_depth.unit : "ft"
                }})</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="flex-item flex_1"
                  :style="{
                    background: darkDark
                      ? gridBackground || 'var(--navBar2Bg)'
                      : gridBackground || 'var(--widgetBg)',
                  }"
                  :id="'tvdTrajectoryTrend' + this.widgetId"
                >
                  <SwabAndSurgeCanvas
                    :totl_height="this.height - 100"
                    :totl_width="this.settingWidth() || 200"
                    :config="config"
                    :data_set="dummy_data"
                    :min_X="config.minX"
                    :min_Y="config.minY"
                    :max_X="config.maxX"
                    :max_Y="config.maxY"
                    :isTooltip="isShow"
                    :isZoom="isZoomFeature"
                    :isLeftBar="sideBarForProp"
                    :axisColor="darkDark != 'white' ? 'white' : 'black'"
                    @show_tool_tip="show_static_tool_tip"
                    @hide_tool_tip="hide_static_tool_tip"
                  />
                </div>
              </div>
              <div style="position: relative; left: 15%; width: 120px">
                <!-- <label
                  :class="[
                    'font-bold',
                    'text-left',
                    'w-11/12',
                    'rounded',
                    'td_btn',
                    'flex',
                    'mt-2',
                  ]"
                > -->
                <div class="togglebtn flex flex-col-reverse w-full font-bold">
                  <div>psi/ppg</div>
                  <input
                    type="checkbox"
                    class="checkbox hidden"
                    :id="'ppg' + this.widgetId"
                    :ref="'theCheckbox1' + this.widgetId"
                    @click="staticppg"
                  />
                  <label
                    :for="'ppg' + this.widgetId"
                    class="checkbox-label td_checkbox_zoom"
                  >
                    <span class="ball"></span>
                  </label>
                </div>
                <!-- </label> -->
              </div>
            </div>
            <div class="w-1/3">
              <div
                class="flex_items relative"
                v-on:dblclick="dblClick('tvdTrajectoryTrend2' + this.widgetId)"
              >
                <div
                  class="graph_line_indicator_div flex items-end"
                  :class="
                    static_graph_2_indicators.length
                      ? 'justify-between'
                      : 'justify-center'
                  "
                >
                  <span
                    class="hdng uppercase font-bold relative"
                    :style="{
                      left: static_graph_2_indicators.length ? '55px' : '25px',
                    }"
                    >Manual graph</span
                  >
                  <div v-if="static_graph_2_indicators.length">
                    <button class="info_btn flex mr-3 ml-auto pt-1">
                      <i class="fas fa-info-circle"></i>
                    </button>
                    <div class="info_popup p-2 absolute right-0 hidden">
                      <div
                        v-for="(obj, index) of static_graph_2_indicators"
                        :key="index"
                        class="pb-1 flex items-center text-xs"
                      >
                        <span
                          style="width: 30px; height: 2px"
                          :style="{ background: obj.color }"
                        ></span>
                        <span class="pl-2 font-semibold"
                          >Trip Speed
                          {{ Number(obj.value).toFixed(2) }} ({{ conversionFactors?.tripp_speed?.unit && conversionFactors?.tripp_speed?.unit!="" ?
                    conversionFactors.tripp_speed.unit : "ft/min"
                }})</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <SwabAndSurgeCanvas
                  :totl_height="this.height - 100"
                  :totl_width="this.settingWidth() || 200"
                  :config="config_plot_2"
                  id="manualgraph2"
                  :data_set="data_plot_2"
                  :min_X="config_plot_2.minX"
                  :min_Y="config_plot_2.minY"
                  :max_X="config_plot_2.maxX"
                  :max_Y="config_plot_2.maxY"
                  :isInvert="true"
                  :isTooltip="isShow"
                  :isZoom="isZoomFeature"
                  :isLeftBar="sideBarForProp"
                  :axisColor="darkDark != 'white' ? 'white' : 'black'"
                  @show_tool_tip="show_static_tool_tip_graph_2"
                  @hide_tool_tip="hide_static_tool_tip_graph_2"
                />
              </div>
            </div>
            <div class="w-1/3">
              <div
                class="flex_items relative"
                v-on:dblclick="dblClick('tvdTrajectoryTrend3_ranges')"
                :value="clicked_graph"
              >
                <div
                  class="graph_line_indicator_div flex justify-center items-end"
                >
                  <span
                    class="hdng uppercase font-bold relative"
                    style="left: 15px"
                    >Real Time</span
                  >
                </div>
                <SwabAndSurgeCanvas
                  :totl_height="this.height - 100"
                  :totl_width="this.settingWidth() || 200"
                  :config="config_plot_3"
                  id="swabsurgeRealtimeGraph"
                  :data_set="data_plot_3"
                  :min_X="config_plot_3.minX"
                  :min_Y="config_plot_3.minY"
                  :max_X="config_plot_3.maxX"
                  :max_Y="config_plot_3.maxY"
                  :isInvert="true"
                  :isTooltip="isShow"
                  :isZoom="isZoomFeature"
                  :isLeftBar="sideBarForProp"
                  :axisColor="darkDark != 'white' ? 'white' : 'black'"
                  @show_tool_tip="show_real_time_tool_tip"
                  @hide_tool_tip="hide_real_time_tool_tip"
                  @real_time_points_right_bar="realTime_show_right_side_bar"
                />
              </div>
              <div class="flex justify-between">
                <div style="position: relative; left: 15%">
                  <!-- <label
                    :class="[
                      'font-bold',
                      'text-left',
                      'w-11/12',
                      'rounded',
                      'td_btn',
                      'flex',
                      'mt-2',
                    ]"
                  > -->
                  <div class="togglebtn flex flex-col-reverse w-full font-bold">
                    <div>psi/ppg</div>
                    <input
                      type="checkbox"
                      class="checkbox hidden"
                      :id="'td_tooltip31' + this.widgetId"
                      v-model="realTime_psi_ppg"
                      @change="realTime_psi_ppg_click"
                    />
                    <label
                      :for="'td_tooltip31' + this.widgetId"
                      class="checkbox-label td_checkbox_zoom"
                    >
                      <span class="ball"></span>
                    </label>
                  </div>
                  <!-- </label> -->
                </div>
                <div class="pl-10 ml-3">
                  <input
                    v-if="show_pause_stop_btn && well_running"
                    class="cursor-pointer text-white active:bg-green-800 text-sx font-bold button uppercase px-3 transition-all duration-150 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear"
                    type="submit"
                    :value="isLoading == true ? pauseOrStop : 'Run'"
                    @click="runfn"
                    :class="[isRun ? 'run' : 'stop']"
                    :disabled="disable_run_btn"
                  />
                  <input
                    v-if="!show_pause_stop_btn && well_running"
                    class="cursor-pointer text-white active:bg-green-800 text-sx font-bold button uppercase px-3 transition-all duration-150 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear"
                    type="submit"
                    :value="'STOP'"
                    @click="() => (this.pauseOrStopPopup = false)"
                    :class="'stop'"
                  />
                  <div class="runInfo" v-show="!runInfoPopup">
                    <div
                      class="popup"
                      :style="{
                        maxHeight: this.widgetdisppopHeight + 'px',
                        width: this.widgetdisppopWidth + 'px',
                      }"
                    >
                      <div class="p-5">
                        <h4 class="font-semibold pb-1 pl-1 text-xxs pb-3">
                          Real Time Swab And Surge Inputs
                        </h4>
                        <div
                          class="mb-2 px-4 flex justify-between items-center"
                          v-if="isLoading"
                        >
                          <label class="block text-xxxs font-semibold"
                            >Trip Depth</label
                          >
                          <input
                            @change="tripdepChange"
                            v-model="tripdep"
                            class="w-1/2 shadow appearance-none border rounded text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                            type="text"
                            style="
                              background: var(--searchBarBg);
                              color: var(--searchColor);
                            "
                          />
                        </div>
                        <div class="flex flex-col">
                          <div >
                            <label class="block text-xxxs font-semibold text-center">
                            Avg. Pipe Length
                          </label>
                          </div>
                        <div class="mb-2 px-4 flex justify-between items-center">
                          <!-- <label class="block text-xxxs font-semibold">
                            Avg. Pipe Length
                          </label>
                          <input
                            class="w-1/2 shadow appearance-none border rounded text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                            type="text"
                            @change="pipelengthChange"
                            v-model="pipelength"
                            style="
                              background: var(--searchBarBg);
                              color: var(--searchColor);
                            "
                          /> -->
                          <div class="flex flex-col">
                            <p class="mud_weight_value">Value</p>
                            <input
                              class="wid w-1/2 shadow appearance-none border rounded text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                              type="text"
                                @change="pipelengthChange"
                                v-model="pipelength"
                                style="
                                  background: var(--searchBarBg);
                                  color: var(--searchColor);
                                "
                            />
                          </div>

                        <div class="unit_select_treep_speed_realtime w-1/2">
                          <UnitSelect :isMetrics="isWellMetrics" :display-id="displayId" :widget-id="widgetId" :mnemonic="'dmea'" :pt="'pt-0'" :category="'Length'" :is-custom-events="true" :selected-val="conversionFactors?.realtime_avg_pipe_length?.unit && conversionFactors?.realtime_avg_pipe_length?.unit!=''?conversionFactors.realtime_avg_pipe_length.unit:isWellMetrics? manualGraphsUnits?.realtime_avg_pipe_length?.metric : manualGraphsUnits?.realtime_avg_pipe_length?.imperial" :standaredUnit="manualGraphsUnits?.realtime_avg_pipe_length?.standardUnits" :reverse="true" @change_unit_handler="(val)=>changeUnitHandler('realtime_avg_pipe_length',val)" :key="unitRefreshKey"/>
                        </div>
                        </div>
                          
                        </div>
                        
                        <div
                          class="mb-2 px-4 flex justify-between items-center"
                        >
                          <label
                            class="block text-xxxs font-semibold"
                            for="password"
                            >Trip Status
                          </label>
                          <select
                            class="w-1/2 selc rounded text-xxxs text-white focus:outline-none focus-visible:outline-none"
                            v-model="statusSelected"
                            @change="tripStatusType"
                            style="
                              background: var(--searchBarBg);
                              color: var(--searchColor);
                            "
                          >
                            <option value="select">Please Select</option>
                            <option
                              :key="option.text"
                              v-for="option in tripstatuscod"
                              :value="option.text"
                            >
                              {{ option.text }}
                            </option>
                          </select>
                        </div>
                        <div
                          class="mb-2 px-4 flex justify-between items-center"
                        >
                          <label class="block text-xxxs font-semibold"
                            >Trip Mode</label
                          >
                          <select
                            class="w-1/2 selc rounded text-xxxs text-white focus:outline-none focus-visible:outline-none"
                            v-model="modeSelected"
                            @change="tripModeType"
                            style="
                              background: var(--searchBarBg);
                              color: var(--searchColor);
                            "
                          >
                            <option value="select">Please Select</option>
                            <option
                              :key="option.text"
                              v-for="option in options"
                              :value="option.text.replace(/\s/g, '')"
                              :class="
                                statusSelected === 'TOOH' &&
                                option.text.replace(/\s/g, '') === 'CLOSEEND'
                                  ? 'hidden'
                                  : ''
                              "
                            >
                              {{ option.text }}
                            </option>
                          </select>
                        </div>
                        <div
                          class="mb-2 pr-5 pt-5 flex justify-end items-center"
                        >
                          <button
                            v-on:click="cancel"
                            class="cnclbtn text-xxs mr-5 font-semibold px-5 py-2"
                          >
                            Cancel
                          </button>
                          <button
                            v-on:click="save"
                            class="svbtn text-xxs mr-5 font-semibold px-5 py-2 border"
                            :disabled="validated"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="runInfo" v-show="!pauseInfoPopup">
                    <div
                      class="popup"
                      :style="{
                        height: '150px',
                        width: '300px',
                      }"
                    >
                      <div
                        class="p-5 w-full h-full flex items-center justify-evenly"
                      >
                        <div class="flex flex-col justify-evenly">
                          <h4
                            class="font-semibold pb-1 pl-1 text-xs capitalize"
                          >
                            Do You Want To Pause For Inserting Data ?
                          </h4>
                          <div
                            class="mb-2 pt-5 flex justify-evenly items-center"
                          >
                            <button
                              v-on:click="yes"
                              class="svbtn text-xxs mr-5 font-semibold px-5 py-2 border"
                            >
                              Yes
                            </button>
                            <button
                              v-on:click="no"
                              class="info text-xxs mr-5 font-semibold px-5 py-2"
                            >
                              No
                            </button>
                            <button
                              v-on:click="cancel"
                              class="cnclbtn text-xxs mr-5 font-semibold px-5 py-2"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="runInfo" v-show="!savePopup">
                    <div
                      class="popup"
                      :style="{
                        maxHeight: this.widgetdisppopHeight + 'px',
                        width: this.widgetdisppopWidth + 'px',
                      }"
                    >
                      <div class="p-5">
                        <h4 class="font-semibold pb-1 pl-1 capitalize text-xxs">
                          Real Time Swab And Surge Inputs
                        </h4>
                        <div
                          class="mb-2 px-4 flex justify-between items-center pt-3"
                        >
                          <label class="block text-xxxs font-semibold"
                            >Name</label
                          >
                          <input
                            v-model="swabName"
                            class="w-1/2 shadow appearance-none border rounded text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                            type="text"
                            style="
                              background: var(--searchBarBg);
                              color: var(--searchColor);
                            "
                          />
                        </div>
                      </div>
                      <div
                        class="mb-2 pr-5 pt-2 pb-3 flex justify-end items-center"
                      >
                        <button
                          v-on:click="cancel"
                          class="cnclbtn text-xxs mr-5 font-semibold px-5 py-2"
                        >
                          Cancel
                        </button>
                        <button
                          v-on:click="Ok"
                          class="svbtn text-xxs mr-5 font-semibold px-5 py-2 border"
                        >
                          OK
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="runInfo" v-show="show_popup_on_zoom">
                    <div
                      class="popup"
                      :style="{
                        maxHeight: this.widgetdisppopHeight + 'px',
                        width: this.widgetdisppopWidth + 'px',
                        paddingBottom: '15px',
                      }"
                    >
                      <div class="p-5 zoom_popup">
                        <h4 class="font-semibold pb-1 pl-1 capitalize text-xxs">
                          Enter Depth
                        </h4>
                        <div
                          class="mb-2 px-4 flex justify-between items-center pt-3"
                        >
                          <label class="block text-xxxs font-semibold uppercase"
                            >From Depth</label
                          >
                          <input
                            v-model="from_Depth"
                            class="w-1/2 shadow appearance-none border rounded text-xxxs font-semibold py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                            type="number"
                            style="
                              background: var(--searchBarBg);
                              color: var(--searchColor);
                            "
                          />
                        </div>
                        <div
                          class="mb-2 px-4 flex justify-between items-center pt-3"
                        >
                          <label class="block text-xxxs font-semibold uppercase"
                            >To Depth</label
                          >
                          <input
                            v-model="to_Depth"
                            class="w-1/2 shadow appearance-none border rounded text-xxxs font-semibold py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                            type="number"
                            style="
                              background: var(--searchBarBg);
                              color: var(--searchColor);
                            "
                          />
                        </div>
                      </div>
                      <div class="mb-2 pr-5 pt-2 flex justify-end items-center">
                        <button
                          v-on:click="hide_popup_on_zoom"
                          class="cnclbtn text-xxs mr-5 font-semibold px-5 py-2"
                        >
                          Cancel
                        </button>
                        <button
                          v-on:click="plot_graph_on_zoom"
                          class="text-xxs mr-5 font-semibold px-2 py-0.5 border"
                          style="
                            color: var(--activeTextColor);
                            border-color: currentColor;
                          "
                        >
                          OK
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="runInfo" v-show="!pauseOrStopPopup">
                    <div
                      class="popup"
                      :style="{
                        maxHeight: this.widgetdisppopHeight + 'px',
                        width: this.widgetdisppopWidth + 'px',
                      }"
                    >
                      <div
                        class="p-5 w-full h-full flex items-center justify-evenly"
                      >
                        <div class="flex flex-col justify-evenly">
                          <h4
                            class="font-semibold pb-1 pl-1 text-xs capitalize"
                          >
                            Do You Want To Stop ?
                          </h4>
                          <div
                            class="mb-2 pt-5 flex justify-evenly items-center"
                          >
                            <button
                              v-on:click="stop"
                              class="cnclbtn text-xxs mr-5 font-semibold px-5 py-2"
                            >
                              Yes
                            </button>
                            <button
                              v-on:click="Nofn"
                              class="svbtn text-xxs mr-5 font-semibold px-5 py-2 border"
                            >
                              No
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
let id = null;
var svg = "";
import axios from "axios";
import apiService from "../../../api/services";
import {
  select,
  scaleLinear,
  axisBottom,
  axisLeft,
  brush,
  line,
  max,
} from "d3";
import Props from "./Swebprops.vue";
import CustomSelect from "../../Select/SelectComponent.vue";
import SwabSurgeNavBar from "../../Navbars/SwabSurgeNavBar.vue";
import SwabSurgeLeftbar from "../../Sidebar/SwabSurgeLeftbar.vue";
import VerticalBarWidget from "../VerticalBar/VerticalBarWidget";
import { devNull } from "os";
import configjs from "../../../config";
import SwabAndSurgeCanvas from "./SwabAndSurgeCanvas.vue";
import UnitSelect from "../../Navbars/properties/UnitSelect.vue";
import swabsurgeMapping from "./swabsurgeManualGraphsUnits.json"
export default {
  name: "swaborsurge-widget",
  components: {
    Props,
    CustomSelect,
    SwabSurgeNavBar,
    SwabSurgeLeftbar,
    VerticalBarWidget,
    SwabAndSurgeCanvas,
    UnitSelect
  },
  emits: ["to_openProperty"],
  props: {
    cardBackground: {
      type: String,
      default: "var(--navBar2Bg)",
    },
    widgetHeight: {
      type: Number,
      default: 200,
    },
    widgetWidth: {
      type: Number,
      default: 350,
    },
    widgetConfig: {
      type: Object,
    },
    widgetIndex: {
      type: Number,
    },
    displayId: String,
    widgetId: {
      type: String,
    },

    // For Graph Plots
    openConfig: Function,
    gridColor: {
      type: String,
      default: "",
    },
    gridBackground: {
      type: String,
      default: "",
    },
    xAxisLabel: {
      type: String,
      default: "",
    },
    yAxisLabel: {
      type: String,
      default: "",
    },
    xAxisLabelSize: {
      type: Number,
    },
    yAxisLabelSize: {
      type: Number,
    },
    backgroundColor: {
      type: String,
      default: "white",
    },
    isSwitched: {
      type: Boolean,
      default: false,
    },
    actualColor: {
      type: String,
      default: "red",
    },
    bhaStartTime: {
      type: String,
      default: "",
    },
    bhaEndTime: {
      type: String,
      default: "",
    },
    highTripDepth: {
      type: String,
      default: "red",
    },
    normalTripDepth: {
      type: String,
      default: "green",
    },
    lowTripDepth: {
      type: String,
      default: "blue",
    },
    mediumTripDepth: {
      type: String,
      default: "yellow",
    },
    trip_Depth: {
      type: [Number, String],
    },
    trip_Depth_TVD: {
      type: [Number, String],
    },
    trip_speed_min: {
      type: [Number, String],
    },
    trip_speed_max: {
      type: [Number, String],
    },
    avg_pipe_leng: {
      type: [Number, String],
    },
    mud_wght: {
      type: [Number, String],
    },
    hole_size: {
      type: [Number, String],
    },
    dc1_od: {
      type: [Number, String],
    },
    dc1_id: {
      type: [Number, String],
    },
    dc1_length: {
      type: [Number, String],
    },
    dc2_od: {
      type: [Number, String],
    },
    dc2_id: {
      type: [Number, String],
    },
    dc2_length: {
      type: [Number, String],
    },
    hwdp_od: {
      type: [Number, String],
    },
    hwdp_id: {
      type: [Number, String],
    },
    hwdp_length: {
      type: [Number, String],
    },
    dp_od: {
      type: [Number, String],
    },
    dp_id: {
      type: [Number, String],
    },
    dp_length: {
      type: [Number, String],
    },
    hole_size: {
      type: [Number, String],
    },
    r300: {
      type: [Number, String],
    },
    r600: {
      type: [Number, String],
    },
    selected_tripmode: {
      type: [Number, String],
    },
    selected_tripstatus: {
      type: [Number, String],
    },
    flow_index: {
      type: [Number, String],
    },
    Consistency_index: {
      type: [Number, String],
    },
    plastic_Viscosity: {
      type: [Number, String],
    },
    static_casing_length: {
      type: [Number, String],
    },
    static_casing_id: {
      type: [Number, String],
    },
    rect_index: {
      type: Number,
      default: null,
    },
    rect_data: {
      type: Object,
      default: null,
    },
    xAutoScale: {
      type: Boolean,
      default: false,
    },
    yAutoScale: {
      type: Boolean,
      default: false,
    },
    xRangeStart: {
      type: [Number, String],
      default: 0,
    },
    xRangeEnd: {
      type: [Number, String],
      default: 10,
    },
    yRangeStart: {
      type: [Number, String],
      default: 0,
    },
    yRangeEnd: {
      type: [Number, String],
      default: 11,
    },
    xAutoScalePpg: {
      type: Boolean,
      default: true,
    },
    yAutoScalePpg: {
      type: Boolean,
      default: false,
    },
    xRangeStartPpg: {
      type: [Number, String],
      default: 8,
    },
    xRangeEndPpg: {
      type: [Number, String],
      default: 15,
    },
    yRangeStartPpg: {
      type: [Number, String],
      default: 0,
    },
    yRangeEndPpg: {
      type: [Number, String],
      default: 11,
    },
    conversionFactors:{
      type:Object,
      required:false 
    }
  },
  data() {
    return {
      config: {
        minX: 0,
        minY: 0,
        maxX: 50,
        maxY: 500,
        unitsPerTickX: 10,
        unitsPerTickY: 45,
        yTitle: this.$refs["theCheckbox1" + this.widgetId]?.checked
          ? "Surge/Swab EMW (ppg)"
          : "Surge/Swab Pressure (psi)",
        xTitle: "Trip Speed (ft/min)",
      },
      config_plot_2: {
        minX: 0,
        minY: 0,
        maxX: 50,
        maxY: 500,
        unitsPerTickX: 10,
        unitsPerTickY: 45,
        yTitle: "Trip Depth (ft)",
        xTitle: this.$refs["theCheckbox1" + this.widgetId]?.checked
          ? "Surge/Swab EMW (ppg)"
          : "Surge/Swab Pressure (psi)",
      },
      config_plot_3: {
        minX: 0,
        minY: 0,
        maxX: 50,
        maxY: 500,
        unitsPerTickX: 10,
        unitsPerTickY: 45,
        yTitle: "Trip Depth (ft)",
        xTitle: this.realTime_psi_ppg
          ? "Surge/Swab EMW (ppg)"
          : "Surge/Swab Pressure (psi)",
      },
      dummy_data: [],
      data_plot_2: [],
      data_plot_3: [],
      rect: {
        width: 70,
        height: 270,
        top: 0,
        left: 0,
        resizable: true,
        minw: 10,
        minh: 10,
        axis: "both",
        parentLim: true,
        snapToGrid: false,
        borderDisplay: false,
        zIndex: 1,
        active: false,
        class: "box-shaddow",
        name: "vertical-bar",
        autoFit: true,
        isWidget: true,
        offsetY: 4.78125,
        offsetX: 31.984375,
        displayId: this.displayId,
        widgetId: this.widgetId + "swabsurge_gauge_1",
        // bgId: "idd460abd695214",
        bgId: this.widgetId + "gauge_1_bgid",
        titleDisplay: true,
        valueDisplay: true,
        title: "tmv",
        unit: "bbl",
        fullName: "TOT MUD VOL",
        titleColor: () => (this.darkDark !== "white" ? "#fff" : "#000"),
        // valueColor: "#ec27eb",
        valueColor: () => (this.darkDark !== "white" ? "#fff" : "#000"),
        verticalcardBG: () => (this.darkDark !== "white" ? "#000" : "#aaa"),
        interval: 50,
        rangeStart: 0,
        rangeEnd: 200,
        label: [""],
      },
      rect2: {
        width: 70,
        height: 270,
        top: 0,
        left: 0,
        resizable: true,
        minw: 10,
        minh: 10,
        axis: "both",
        parentLim: true,
        snapToGrid: false,
        borderDisplay: false,
        zIndex: 1,
        active: false,
        class: "box-shaddow",
        name: "vertical-bar",
        autoFit: true,
        isWidget: true,
        offsetY: 4.78125,
        offsetX: 31.984375,
        displayId: this.displayId,
        widgetId: this.widgetId + "swabsurge_gauge_2",
        bgId: this.widgetId + "gauge_2_bgid",
        // bgId: "",
        titleDisplay: true,
        valueDisplay: true,
        title: "tt_vol",
        unit: "bbl",
        fullName: "TOT TTK VOL",
        titleColor: () => (this.darkDark !== "white" ? "#fff" : "#000"),
        // valueColor: "#ec27eb",
        valueColor: () => (this.darkDark !== "white" ? "#fff" : "#000"),
        verticalcardBG: () => (this.darkDark !== "white" ? "#000" : "#aaa"),
        interval: 30,
        rangeStart: 0,
        rangeEnd: 150,
        label: [""],
      },
      wId: null,
      tempWidgetId: null,

      // For Graph
      selectedSavedRun: {},
      selectedWellInfo: {},
      isZoomFeature: false,
      mouse_x: 0,
      mouse_y: 0,
      isPlanTooltip: false,
      planTooltipValues: "",
      realtimeTimeOut: "",
      isSaved: false,
      whichShow: "drilling",
      isRealtime: false,
      sideBarForProp: "",
      width: this.widgetWidth,
      height: this.widgetHeight,
      graphData: [],
      graph3Data: [],
      columns: [],
      rtColumn: [],
      plannedColumn: [],
      colors: [],
      minX: 0,
      maxX: 100,
      minY: 0,
      maxY: 100,
      isZoomed: false,
      extent: [],
      extent_static_graph_2: [],
      extent_real_time_graph: [],
      soColor: "",
      puColor: "",
      robColor: "",
      slackoff: "",
      pickup: "",
      rotateoffbottom: "",
      slackofftripin: "",
      pickuptripout: "",
      toggleSidebar: "-16",
      sideBarFor: "",
      tdopen: false,
      isShow: false,

      planId: "",
      bhaId: "",
      sourceid: "",
      bhaName: "",
      start_time: this.bhaStartTime,
      end_time: this.bhaEndTime,
      bhaCount: 0,
      selectedBhaSourceId: "",
      selected_start_time: "",
      selected_end_time: "",
      wellFormation: [],
      geometry: [],
      zoom_checked: this.isZoomFeature,
      curveProps: {},
      trackConfig: {},
      tracks: [],
      isLoading: false,
      isLoadings: false,
      isRun: true,
      isPause: true,
      modeSelected: "select",
      statusSelected: "select",
      selected_trip_mode: this.selected_tripmode || "",
      options: [{ text: "OPEN END" }, { text: "CLOSE END" }],
      selected_trip_statues: this.selected_tripstatus || "",
      selected_trip_status: "",
      tripstatuscod: [{ text: "TIH" }, { text: "TOOH" }],
      runInfoPopup: true,
      savePopup: true,
      pauseInfoPopup: true,
      pauseOrStopPopup: true,
      pauseOrStop: "Pause / Stop",
      UserType: "",
      capture_screen_confirm_box: "",
      isSupportScreenshotShowing: false,
      capturedImage: "",
      tripdep: "",
      trdepth: "",
      pausedDepth: "",
      pipelength: "",
      action: "",
      pausedDepth: "",
      pausedTime: "",
      swabAndSurgePressureArray: [],
      swabAndSurgePressureinppg: "",
      customer: "",
      swabSurgeInputDataId: "",
      createdAt: "",
      // speed_max: "",
      // speed_min: "",
      // stand_speed_max: "",
      // stand_speed_min: "",
      // pipe_length: "",
      mud_density: this.mud_wght,
      megaData: {},
      megaData2: {},
      megaData3: [],
      trip_Depth_maxy: this.trip_Depth,
      max: "",
      minXZoom: "",
      maxXZoom: "",
      minYZoom: "",
      maxYZoom: "",
      minXZoom_static_grph_2: "",
      maxXZoom_static_grph_2: "",
      minYZoom_static_grph_2: "",
      maxYZoom_static_grph_2: "",
      minXZoom_real_time_grph: "",
      maxXZoom_real_time_grph: "",
      minYZoom_real_time_grph: "",
      maxYZoom_real_time_grph: "",
      widgetdisppopHeight: 250,
      widgetdisppopWidth: 270,
      status: "",
      clicked_graph: "",
      swabName: "",
      wellId: "",
      wellboreId: "",
      validated: true,
      pipelengthCng: "",
      modeSelectedCng: "",
      toUpdate_ss_save_list: false,
      realTime_psi_ppg: false,
      rect_index: this.rect_index,
      rect_data: this.rect_data,
      ss_trigger_clicked_swabsurgeName: "",
      show_popup_on_zoom: false,
      from_Depth: null,
      to_Depth: null,
      interval_to_check_active: null,
      disable_run_btn: false,
      rerun_after_pause: false,
      show_print_popup: false,
      real_time_tool_tip: false,
      real_time_hole_depth: null,
      real_time_bit_depth: null,
      real_time_trip_speed: null,
      real_time_surge_pressure: null,
      real_time_swab_pressure: null,
      real_time_surge_pressure_in_ppg: null,
      real_time_swab_pressure_in_ppg: null,
      real_time_trip_status: null,
      static_graph_1_indicators: [],
      static_graph_2_indicators: [],
      static_graph_1_speed: null,
      static_graph_1_bit_depth: null,
      static_graph_1_swabAndSurgePressure: null,
      static_graph_1_swabAndSurgePressureinppg: null,
      static_graph_1_tool_tip: false,
      static_graph_1_trip_depth: null,
      static_graph_1_trip_speed_min: null,
      static_graph_1_trip_speed_max: null,
      static_graph_2_trip_depth: null,
      static_graph_2_trip_speed: null,
      static_graph_2_bit_depth: null,
      static_graph_2_swabAndSurgePressure: null,
      static_graph_2_swabAndSurgePressureinppg: null,
      static_graph_2_tool_tip: false,
      swab_surge_trigger_data: [],
      show_pause_stop_btn: true,
      well_running: false,
      unitRefreshKey:675439,
      isWellMetrics:false,
      manualGraphsUnits: swabsurgeMapping,
    };
  },
  beforeMount() {
    let id = "id" + Math.random().toString(16).slice(2);
    this.tempWidgetId = id;
    // For Graph
    if (this.realtimeTimeOut) {
      clearTimeout(this.realtimeTimeOut);
    }
  },
  sockets: {
    connect: function () {
      console.log("socket connected in Swab");
    },
    disconnect() {
      try {
        let topic = this.topic;
        this.$socket.emit("unsubscribe", {
          // logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
          logId: `${this.wellId}_${this.wellboreId}_${this.ss_trigger_clicked_swabsurgeName}`,
          topic: this.topic,
          id: this.widgetId,
          clientId: this.$clientId,
        });
      } catch (error) {
        console.error("disconnect unsubscribe error ", error);
      }
    },

    reconnect() {
      this.$socket.emit("subscribe", {
        logId: `${this.wellId}_${this.wellboreId}_${this.ss_trigger_clicked_swabsurgeName}`,
        topic: this.topic,
        fields: this.fields,
        id: this.widgetId,
        clientId: this.$clientId,
      });
      console.log("----------------------------------------");
    },
  },
  beforeUnmount() {
    clearInterval(this.interval_to_check_active);
    let topic = this.topic;
    if (this.$socket) {
      let tempId = this.widgetId;
      this.$socket.emit("unsubscribe", {
        logId: `${this.wellId}_${this.wellboreId}_${this.ss_trigger_clicked_swabsurgeName}`,
        topic: this.topic,
        id: this.widgetId,
        clientId: this.$clientId,
      });
      this.sockets.unsubscribe(this.$clientId);
    }
  },
  unmounted() {
    clearInterval(this.interval_to_check_active);
  },
  methods: {
    commonDispatcher(val, key){
      this.$store.dispatch("rect/swabSurgeRealTimedata", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: val,
        field: key,
      });
    },
    changeUnitHandler(key,factor){
        const fact= {unit:factor.to_unit,conversion_factor:factor.conversion_factor};
        if(key == 'average_pipe_length'){
          // this.pipe_length *= factor.conversion_factor;
          // this.commonDispatcher(this.pipe_length * factor.conversion_factor, 'pipe_length')
        }else if(key == 'tripp_speed'){
          // this.speed_min *= factor.conversion_factor;
          // this.speed_max *= factor.conversion_factor;
          // this.commonDispatcher(this.speed_min * factor.conversion_factor, 'speed_min')
          // this.commonDispatcher(this.speed_max * factor.conversion_factor, 'speed_max')
        }
        this.$store.dispatch("rect/changSwabSearchUnit", {
          displayId: this.displayId,
          widgetId: this.widgetId,
          key: key,
          value: fact,
        });
      },
    settingWidth() {
      if (this.sideBarForProp == "TD") {
        // return (this.width - 390 - 160) / 3 - 70;
        return (this.width - 390 - 160) / 3;
      }
      // else if(this.$store.state?.disp?.toggle_right_Sidebar){
      //   let calcWidth = window.innerWidth * 0.11
      //   return (this.width - 390 - calcWidth) / 3 - 70
      // }
      else {
        // return (this.width - 390) / 3 - 70;
        return (this.width - 390) / 3;
      }
    },
    getRealtimeData(from) {
      let topic = this.topic;
      // console.log("get real time data : ", from, {
      //   logId: `${this.wellId}_${this.wellboreId}_${this.ss_trigger_clicked_swabsurgeName}`,
      //   topic: topic,
      //   fields: "fields",
      //   id: this.widgetId,
      //   clientId: this.$clientId,
      //   logType: this.logType,
      // });
      if (topic) {
        let fields = ["surge_pressure_ppg"];
        this.fields = fields;
        let topicExists = this.$store.state.live.isSubscribed[topic];

        // if (this.logId) {
        this.$socket.emit("subscribe", {
          logId: `${this.wellId}_${this.wellboreId}_${this.ss_trigger_clicked_swabsurgeName}`,
          topic: topic,
          fields,
          id: this.widgetId,
          clientId: this.$clientId,
          logType: this.logType,
        });
        if (!topicExists) {
          this.$store.dispatch("live/setSubscriptionFlag", {
            topic,
            flag: true,
          });
        }
        this.sockets.subscribe(this.$clientId + "_SWAB_SURGE_", (sResult) => {
          // console.log("swab and surge stream : ", sResult);
          // let common = sResult["common"];
          if (sResult[this.widgetId]) {
            let result = sResult[this.widgetId];
            // this.graph3Data.push(result);
            if (
              result !== null &&
              result?.swabSurgeDataId.includes(this.wellId)
            ) {
              this.graph3Data.push(result);
              this.set3DGraph3Data();
            }
          }
          //       {
          //     "swabSurgeDataId": "ABS",
          //     "dbtm": 1.0,
          //     "swabSurgeData": {
          //         "bitSize": "20",
          //         "casingid": "20",
          //         "casinglength": "20",
          //         "consistencyIndex": "20",
          //         "dc1Casinglength": "20",
          //         "dc1OpenHolelength": "20",
          //         "dc1id": "20",
          //         "dc1length": "20",
          //         "dc1od": "10",
          //         "dc2Casinglength": "20",
          //         "dc2OpenHolelength": "20",
          //         "dc2id": "20",
          //         "dc2length": "20",
          //         "dc2od": "20",
          //         "dmea": "100",
          //         "dpCasinglength": "20",
          //         "dpOpenHolelength": "20",
          //         "dpid": "20",
          //         "dplength": "20",
          //         "dpod": "20",
          //         "flowIndex": "20",
          //         "hwdpCasinglength": "20",
          //         "hwdpOpenHolelength": "20",
          //         "hwdpid": "20",
          //         "hwdplength": "20",
          //         "hwdpod": "20",
          //         "muddensity": "20",
          //         "plasticViscosity": "20",
          //         "surgePressure": "200",
          //         "surgePressureinppg": "300",
          //         "swabPressure": "67",
          //         "swabPressureinppg": "789",
          //         "time": "1683012466000",
          //         "tripMode": "OPENEND",
          //         "tripSpeed": "150",
          //         "tripStatus": "TIH"
          //     }
          // }
          // if (result) {
          //     if (this.logType == "depth") {
          //         this.tooltipValue = common["dmea"];
          //     }
          //     if (this.logType == "time") {
          //         this.tooltipValue = new Date(
          //             parseInt(common["timestamp"])
          //         ).toLocaleString();
          //     }
          //     this.numericValue = result[title] || result[this.title];
          // }
        });
        // }
        topicExists = this.$store.state.live.isSubscribed[topic];
      }
    },
    takeOffSocket() {
      if (this.$socket) {
        this.$socket.emit("unsubscribe", {
          logId: `${this.wellId}_${this.wellboreId}_${this.ss_trigger_clicked_swabsurgeName}`,
          topic: this.topic,
          id: this.widgetId,
          clientId: this.$clientId,
        });
        this.sockets.unsubscribe(this.$clientId);
      }
    },
    processResult(sResult) {
      console.log("processResult ", sResult);
      console.log("processResult widgetId", this.widgetId);
    },
    windowToggle(name) {
      this.classes = name;
    },
    dark() {
      document.querySelector("nav").classList.add("dark-mode");
      document.querySelector("div").classList.add("dark-mode");
      document.querySelector("button").classList.add("dark-mode");
      document.querySelector("a").classList.add("text-red-600");
      this.darkMode = true;
      this.$emit("dark");
    },
    light() {
      document.querySelector("nav").classList.remove("dark-mode");
      document.querySelector("div").classList.remove("dark-mode");
      document.querySelector("button").classList.remove("dark-mode");
      document.querySelector("a").classList.remove("text-red-600");
      this.darkMode = false;
      this.$emit("light");
    },
    // For Graph
    tooltipOn() {
      this.isShow = !this.isShow;
      this.buildSVG1(this.widgetId);
      this.buildSVG2(this.widgetId);
      this.buildSVG3(this.widgetId);
    },
    zoomOnOff(id) {
      this.isZoomFeature = !this.isZoomFeature;
      if (this.isZoomFeature == true) {
        // alert("zoom off");
        this.extent = [];
        this.extent_static_graph_2 = [];
        this.extent_real_time_graph = [];
        this.buildSVG1(this.widgetId);
        this.buildSVG2(this.widgetId);
        // if (this.ss_trigger_clicked_swabsurgeName) {
        //   this.ss_trigger_list_click(this.ss_trigger_clicked_swabsurgeName);
        // }
        this.buildSVG3(this.widgetId);
      } else {
        this.zoomOut();
      }
    },
    async staticppg() {
      await this.apiGraph1Data();
      await this.apiGraph2Data();
    },
    async getWellFormations() {
      try {
        let formationMakersResponse =
          await apiService.WellServices.getWellFormations({
            sourceid: this.sourceid,
          });
        this.wellFormation = formationMakersResponse.data.rows;
      } catch (error) {
        console.error(error);
      }
    },
    async getWellGeometry() {
      try {
        let geometryResponse = await apiService.WellServices.getWellGeometry({
          planid: this.planId,
        });
        this.geometry = geometryResponse.data.rows;
      } catch (error) {
        console.error(error);
      }
    },
    zoomOut() {
      this.isZoomed = false;
      this.buildSVG1(this.widgetId);
      this.buildSVG2(this.widgetId);
      if (this.ss_trigger_clicked_swabsurgeName) {
        let result = false;
        if (this.swab_surge_trigger_data != "") {
          result = this.swab_surge_trigger_data.some(
            (each) =>
              each.swabSurgeName.replace(/\s/g, "")?.toLowerCase() ==
                this.ss_trigger_clicked_swabsurgeName
                  .replace(/\s/g, "")
                  ?.toLowerCase() && each.status == 0
          );
        }
        if (result) {
          this.ss_trigger_list_click(
            this.ss_trigger_clicked_swabsurgeName,
            false,
            "running"
          );
        } else {
          this.ss_trigger_list_click(this.ss_trigger_clicked_swabsurgeName);
        }
      }
      else{
        this.buildSVG3(this.widgetId);
      }
      // this.buildSVG3(this.widgetId);
    },
    showAll() {
      this.$refs["back_to_original" + this.widgetId].checked = true;
      this.extent = [];
      this.extent_static_graph_2 = [];
      this.extent_real_time_graph = [];
      setTimeout(() => {
        this.$refs["back_to_original" + this.widgetId].checked = false;
      }, 1000);
      this.buildSVG1(this.widgetId);
      this.buildSVG2(this.widgetId);
      if (this.ss_trigger_clicked_swabsurgeName) {
        let result = false;
        if (this.swab_surge_trigger_data != "") {
          result = this.swab_surge_trigger_data.some(
            (each) =>
              each.swabSurgeName.replace(/\s/g, "")?.toLowerCase() ==
                this.ss_trigger_clicked_swabsurgeName
                  .replace(/\s/g, "")
                  ?.toLowerCase() && each.status == 0
          );
        }
        if (result) {
          this.ss_trigger_list_click(
            this.ss_trigger_clicked_swabsurgeName,
            false,
            "running"
          );
        } else {
          this.ss_trigger_list_click(this.ss_trigger_clicked_swabsurgeName);
        }
      }
      // this.buildSVG3(this.widgetId);
    },
    tripdepChange() {
      if (
        this.tripdep != this.trdepth ||
        this.pipelength != this.pipelengthCng ||
        this.modeSelected != this.modeSelectedCng
      ) {
        this.validated = false;
      } else {
        this.validated = true;
      }
    },
    pipelengthChange() {
      if (
        this.tripdep != this.trdepth ||
        this.pipelength != this.pipelengthCng ||
        this.modeSelected != this.modeSelectedCng
      ) {
        this.validated = false;
      } else {
        this.validated = true;
      }
    },
    tripStatusType() {
      if (this.statusSelected === "TOOH") {
        this.modeSelected = "OPENEND";
        this.tripModeType();
      } else {
        this.modeSelected = "select";
      }
    },
    tripModeType() {
      if (
        this.tripdep != this.trdepth ||
        this.pipelength != this.pipelengthCng ||
        this.modeSelected != this.modeSelectedCng
      ) {
        this.validated = false;
      } else {
        this.validated = true;
      }
    },
    async renderGraph() {
      await this.get3DGraphData();
      await this.getWellFormations();
      await this.getWellGeometry();
      this.buildSVG1(this.widgetId);
      this.buildSVG2(this.widgetId);
      this.buildSVG3(this.widgetId);
    },
    setContextIdWidget(e) {
      this.$store.dispatch("disp/setContextId", {
        contextId: this.displayId,
        contextWidgetId: this.widgetId,
        contextType: "widget",
      });
    },
    async get3DGraphData() {
      if (this.trip_Depth) {
        // if (
        //   this.dc1_length &&
        //   this.dc2_length &&
        //   this.hwdp_length &&
        //   this.dp_length
        // ) {
        let total_length =
          (parseFloat(this.dc1_length) || 0) +
          (parseFloat(this.dc2_length) || 0) +
          (parseFloat(this.hwdp_length) || 0) +
          (parseFloat(this.dp_length) || 0);
        if (total_length !== parseFloat(this.trip_Depth)) {
          let arr = ["DC1 LENGTH", "DC2 LENGTH", "HWDP LENGTH", "DP LENGTH"];
          this.$toast.clear();
          this.$toast.error(
            `Sum of "${arr}" should be equal to the "TRIP DEPTH"`,
            { duration: "2000", position: "top", pauseOnHover: true }
          );
          this.graphData = [];
          this.megaData = {};
          this.megaData2 = {};
          this.buildSVG1(this.widgetId);
          this.buildSVG2(this.widgetId);
        } else if (this.speed_min == "") {
          this.$toast.clear();
          this.$toast.error(`Please enter trip speed "Min" value`, {
            duration: "2000",
            position: "top",
            pauseOnHover: true,
          });
          this.graphData = [];
          this.megaData = {};
          this.megaData2 = {};
          this.buildSVG1(this.widgetId);
          this.buildSVG2(this.widgetId);
        } else if (this.speed_max == "") {
          this.$toast.clear();
          this.$toast.error(`Please enter trip speed "Max" value`, {
            duration: "2000",
            position: "top",
            pauseOnHover: true,
          });
          this.graphData = [];
          this.megaData = [];
          this.megaData2 = [];
          this.buildSVG1(this.widgetId);
          this.buildSVG2(this.widgetId);
        } else {
          this.$toast.clear();
          await this.apiGraph1Data();
          await this.apiGraph2Data();
        }
        // }
      }
    },
    onToggleSideBar() {
      if (this.wellId && this.wellboreId) {
        if (this.sideBarForProp == "TD") {
          this.sideBarForProp = "";
          this.toggleSidebar = 0;
        } else {
          this.sideBarForProp = "TD";
          this.toggleSidebar = -16;
        }
        try {
          this.buildSVG1(this.widgetId);
          this.buildSVG2(this.widgetId);
          this.buildSVG3(this.widgetId);
        } catch (err) {
          console.error(err);
        }
      } else {
        this.$toast.clear();
        this.$toast.info("Please select Wells", {
          position: "top",
          duration: 1000,
        });
      }
    },
    async apiGraph1Data() {
      if (this.selected_trip_statues != "") {
        if (this.selected_trip_statues.toUpperCase() == "TIH") {
          this.selected_trip_status = 5;
        }
        if (this.selected_trip_statues.toUpperCase() == "TOOH") {
          this.selected_trip_status = 6;
        }
      }
      if (
        this.selected_trip_status &&
        this.trip_Depth &&
        this.hole_size &&
        this.selected_trip_mode
      ) {
        let payload={
              dpid: parseFloat(this.dp_id) || 0,
              dpod: parseFloat(this.dp_od) || 0,
              dc1od: parseFloat(this.dc1_od) || 0,
              dc1id: parseFloat(this.dc1_id) || 0,
              dc2od: parseFloat(this.dc2_od) || 0,
              dc2id: parseFloat(this.dc2_id) || 0,
              hwdpod: parseFloat(this.hwdp_od) || 0,
              hwdpid: parseFloat(this.hwdp_id) || 0,
              dplength: parseFloat(this.dp_length) || 0,
              hwdplength: parseFloat(this.hwdp_length) || 0,
              dc1length: parseFloat(this.dc1_length) || 0,
              dc2length: parseFloat(this.dc2_length) || 0,
              r300: parseFloat(this.r300),
              r600: parseFloat(this.r600),
              flowIndex: parseFloat(this.flow_index),
              // "flowIndex": 10,
              consistencyIndex: parseFloat(this.Consistency_index),
              // "consistencyIndex": 10,
              muddensity: parseFloat(this.mud_wght),
              plasticViscosity: parseFloat(this.plastic_Viscosity),
              // "plasticViscosity": 10,
              minPipeVelocity: parseFloat(this.speed_min),
              maxPipeVelocity: parseFloat(this.speed_max),
              bitSize: parseFloat(this.hole_size),
              tvd: parseFloat(this.trip_Depth_TVD),
              dbtm: parseFloat(this.trip_Depth),
              activityCode: this.selected_trip_status,
              tripMode: this.selected_trip_mode.toUpperCase(),
              depthSpeedSwabSurge: "SPEEDSWABSURGE",
              casinglength: parseFloat(this.static_casing_length),
              casingid: parseFloat(this.static_casing_id),
            };
            console.log('Before payload:',payload);
            if(this.conversionFactors?.trip_depth?.conversion_factor && this.conversionFactors?.trip_depth?.conversion_factor!="" ){
              payload.dbtm*= this.conversionFactors.trip_depth.conversion_factor;
            }
            if(this.conversionFactors?.trip_depth_tvd?.conversion_factor && this.conversionFactors?.trip_depth_tvd?.conversion_factor!="" ){
              payload.tvd *= this.conversionFactors.trip_depth_tvd.conversion_factor;
            }
            if(this.conversionFactors?.hole_size?.conversion_factor && this.conversionFactors?.hole_size?.conversion_factor!="" ){
              payload.bitSize *= this.conversionFactors.hole_size.conversion_factor;
            }
            // if(this.conversionFactors?.hole_size?.conversion_factor && this.conversionFactors?.hole_size?.conversion_factor!="" ){
            //   payload.bitSize /= this.conversionFactors.hole_size.conversion_factor;
            // }
            if(this.conversionFactors?.dc1_od?.conversion_factor && this.conversionFactors?.dc1_od?.conversion_factor!="" ){
              payload.dc1od *= this.conversionFactors.dc1_od.conversion_factor;
            }
            if(this.conversionFactors?.dc1_id?.conversion_factor && this.conversionFactors?.dc1_id?.conversion_factor!="" ){
              payload.dc1id *= this.conversionFactors.dc1_id.conversion_factor;
            }
            if(this.conversionFactors?.dc1_length?.conversion_factor && this.conversionFactors?.dc1_length?.conversion_factor!="" ){
              payload.dc1length *= this.conversionFactors.dc1_length.conversion_factor;
            }
            if(this.conversionFactors?.dc2_od?.conversion_factor && this.conversionFactors?.dc2_od?.conversion_factor!="" ){
              payload.dc2od *= this.conversionFactors.dc2_od.conversion_factor;
            }
            if(this.conversionFactors?.dc2_id?.conversion_factor && this.conversionFactors?.dc2_id?.conversion_factor!="" ){
              payload.dc2id *= this.conversionFactors.dc2_id.conversion_factor;
            }
            if(this.conversionFactors?.dc2_length?.conversion_factor && this.conversionFactors?.dc2_length?.conversion_factor!="" ){
              payload.dc2length *= this.conversionFactors.dc2_length.conversion_factor;
            }
            if(this.conversionFactors?.hwdp_od?.conversion_factor && this.conversionFactors?.hwdp_od?.conversion_factor!="" ){
              payload.hwdpod *= this.conversionFactors.hwdp_od.conversion_factor;
            }
            if(this.conversionFactors?.hwdp_id?.conversion_factor && this.conversionFactors?.hwdp_id?.conversion_factor!="" ){
              payload.hwdpid *= this.conversionFactors.hwdp_id.conversion_factor;
            }
            if(this.conversionFactors?.hwdp_length?.conversion_factor && this.conversionFactors?.hwdp_length?.conversion_factor!="" ){
              payload.hwdplength *= this.conversionFactors.hwdp_length.conversion_factor;
            }
            if(this.conversionFactors?.dp_od?.conversion_factor && this.conversionFactors?.dp_od?.conversion_factor!="" ){
              payload.dpod *= this.conversionFactors.dp_od.conversion_factor;
            }
            if(this.conversionFactors?.dp_id?.conversion_factor && this.conversionFactors?.dp_id?.conversion_factor!="" ){
              payload.dpid *= this.conversionFactors.dp_id.conversion_factor;
            }
            if(this.conversionFactors?.dp_length?.conversion_factor && this.conversionFactors?.dp_length?.conversion_factor!="" ){
              payload.dplength *= this.conversionFactors.dp_length.conversion_factor;
            }
            if(this.conversionFactors?.casing_length?.conversion_factor && this.conversionFactors?.casing_length?.conversion_factor!="" ){
              payload.casinglength *= this.conversionFactors.casing_length.conversion_factor;
            }
            if(this.conversionFactors?.casing_id?.conversion_factor && this.conversionFactors?.casing_id?.conversion_factor!="" ){
              payload.casingid *= this.conversionFactors.casing_id.conversion_factor;
            }
            if(this.conversionFactors?.mud_density?.conversion_factor && this.conversionFactors?.mud_density?.conversion_factor!="" ){
              payload.muddensity *= this.conversionFactors.mud_density.conversion_factor;
            }
            if(this.conversionFactors?.plactic_viscosity?.conversion_factor && this.conversionFactors?.plactic_viscosity?.conversion_factor!="" ){
              payload.plasticViscosity *= this.conversionFactors.plactic_viscosity.conversion_factor;
            }
            if(this.conversionFactors?.tripp_speed?.conversion_factor && this.conversionFactors?.tripp_speed?.conversion_factor!="" ){
              payload.minPipeVelocity *= this.conversionFactors.tripp_speed.conversion_factor;
            }
            if(this.conversionFactors?.tripp_speed?.conversion_factor && this.conversionFactors?.tripp_speed?.conversion_factor!="" ){
              payload.maxPipeVelocity *= this.conversionFactors.tripp_speed.conversion_factor;
            }
            console.log('After payload:',payload);
        await axios
          .post(
            configjs.HOST_URL.DATASERVICES_URL + "swabsurgesolution/start",payload)
          .then((resp) => {
            this.graphData = resp.data;
            // trip_speed, dbtm, depth, minPipeVelocity, maxPipevelocity, swabAndSurgePressure, swabAndSurgePressureinppg
            this.graphData.forEach(e => {
              if(this.conversionFactors?.tripp_speed?.conversion_factor && this.conversionFactors?.tripp_speed?.conversion_factor!="" ){
                e.speed /= this.conversionFactors.tripp_speed.conversion_factor;
              }
              // if(this.conversionFactors?.hole_size?.conversion_factor && this.conversionFactors?.hole_size?.conversion_factor!="" ){
              //   e.swabSurgeList.bitSize /= this.conversionFactors.hole_size.conversion_factor;
              // }
              // if(this.conversionFactors?.casing_id?.conversion_factor && this.conversionFactors?.casing_id?.conversion_factor!="" ){
              //   e.swabSurgeList.casingid *= this.conversionFactors.casing_id.conversion_factor;
              // }
              // if(this.conversionFactors?.casing_length?.conversion_factor && this.conversionFactors?.casing_length?.conversion_factor!="" ){
              //   e.swabSurgeList.casinglength *= this.conversionFactors.casing_length.conversion_factor;
              // }
              e.swabSurgeList.forEach((swabSurgeList)=>{
                if(this.conversionFactors?.trip_depth?.conversion_factor && this.conversionFactors?.trip_depth?.conversion_factor!="" ){
                  swabSurgeList.dbtm/= this.conversionFactors.trip_depth.conversion_factor;
                }
                if(this.conversionFactors?.trip_depth?.conversion_factor && this.conversionFactors?.trip_depth?.conversion_factor!="" ){
                  swabSurgeList.depth/= this.conversionFactors.trip_depth.conversion_factor;
                }
                if(this.conversionFactors?.tripp_speed?.conversion_factor && this.conversionFactors?.tripp_speed?.conversion_factor!="" ){
                  swabSurgeList.minPipeVelocity/= this.conversionFactors.tripp_speed.conversion_factor;
                }
                if(this.conversionFactors?.tripp_speed?.conversion_factor && this.conversionFactors?.tripp_speed?.conversion_factor!="" ){
                  swabSurgeList.maxPipeVelocity/= this.conversionFactors.tripp_speed.conversion_factor;
                }
                if(this.conversionFactors?.mud_density?.conversion_factor && this.conversionFactors?.mud_density?.conversion_factor!="" ){
                  swabSurgeList.swabAndSurgePressureinppg/= this.conversionFactors.mud_density.conversion_factor;
                }
              })
              // if(this.conversionFactors?.dc1_length?.conversion_factor && this.conversionFactors?.dc1_length?.conversion_factor!="" ){
              //   payload.dc1length *= this.conversionFactors.dc1_length.conversion_factor;
              // }
            });
            this.set3DGraph1Data();
          })
          .catch((err) => {
            this.set3DGraph1Data();
            console.error(err);
          });
      } else {
        this.set3DGraph1Data();
      }
    },
    async apiGraph2Data() {
      if (this.selected_trip_statues != "") {
        if (this.selected_trip_statues.toUpperCase() == "TIH") {
          this.selected_trip_status = 5;
        }
        if (this.selected_trip_statues.toUpperCase() == "TOOH") {
          this.selected_trip_status = 6;
        }
      }
      if (
        this.selected_trip_status &&
        this.trip_Depth &&
        this.hole_size &&
        this.selected_trip_mode
      ) {
        let payload = {
              dpid: parseFloat(this.dp_id) || 0,
              dpod: parseFloat(this.dp_od) || 0,
              dc1od: parseFloat(this.dc1_od) || 0,
              dc1id: parseFloat(this.dc1_id) || 0,
              dc2od: parseFloat(this.dc2_od) || 0,
              dc2id: parseFloat(this.dc2_id) || 0,
              hwdpod: parseFloat(this.hwdp_od) || 0,
              hwdpid: parseFloat(this.hwdp_id) || 0,
              dplength: parseFloat(this.dp_length) || 0,
              hwdplength: parseFloat(this.hwdp_length) || 0,
              dc1length: parseFloat(this.dc1_length) || 0,
              dc2length: parseFloat(this.dc2_length) || 0,
              r300: parseFloat(this.r300),
              r600: parseFloat(this.r600),
              flowIndex: parseFloat(this.flow_index),
              // "flowIndex": 10,
              consistencyIndex: parseFloat(this.Consistency_index),
              // "consistencyIndex": 10,
              muddensity: parseFloat(this.mud_wght),
              plasticViscosity: parseFloat(this.plastic_Viscosity),
              // "plasticViscosity": 10,
              minPipeVelocity: parseFloat(this.speed_min),
              maxPipeVelocity: parseFloat(this.speed_max),
              bitSize: parseFloat(this.hole_size),
              tvd: parseFloat(this.trip_Depth_TVD),
              dbtm: parseFloat(this.trip_Depth),
              activityCode: this.selected_trip_status,
              tripMode: this.selected_trip_mode.toUpperCase(),
              depthSpeedSwabSurge: "DEPTHSWABSURGE",
              casinglength: parseFloat(this.static_casing_length),
              casingid: parseFloat(this.static_casing_id),
            };

            if(this.conversionFactors?.trip_depth?.conversion_factor && this.conversionFactors?.trip_depth?.conversion_factor!="" ){
              payload.dbtm *= this.conversionFactors.trip_depth.conversion_factor;
            }
            if(this.conversionFactors?.trip_depth_tvd?.conversion_factor && this.conversionFactors?.trip_depth_tvd?.conversion_factor!="" ){
              payload.tvd *= this.conversionFactors.trip_depth_tvd.conversion_factor;
            }
            if(this.conversionFactors?.hole_size?.conversion_factor && this.conversionFactors?.hole_size?.conversion_factor!="" ){
              payload.bitSize *= this.conversionFactors.hole_size.conversion_factor;
            }
            // if(this.conversionFactors?.hole_size?.conversion_factor && this.conversionFactors?.hole_size?.conversion_factor!="" ){
            //   payload.bitSize *= this.conversionFactors.hole_size.conversion_factor;
            // }
            if(this.conversionFactors?.dc1_od?.conversion_factor && this.conversionFactors?.dc1_od?.conversion_factor!="" ){
              payload.dc1od *= this.conversionFactors.dc1_od.conversion_factor;
            }
            if(this.conversionFactors?.dc1_id?.conversion_factor && this.conversionFactors?.dc1_id?.conversion_factor!="" ){
              payload.dc1id *= this.conversionFactors.dc1_id.conversion_factor;
            }
            if(this.conversionFactors?.dc1_length?.conversion_factor && this.conversionFactors?.dc1_length?.conversion_factor!="" ){
              payload.dc1length *= this.conversionFactors.dc1_length.conversion_factor;
            }
            if(this.conversionFactors?.dc2_od?.conversion_factor && this.conversionFactors?.dc2_od?.conversion_factor!="" ){
              payload.dc2od *= this.conversionFactors.dc2_od.conversion_factor;
            }
            if(this.conversionFactors?.dc2_id?.conversion_factor && this.conversionFactors?.dc2_id?.conversion_factor!="" ){
              payload.dc2id *= this.conversionFactors.dc2_id.conversion_factor;
            }
            if(this.conversionFactors?.dc2_length?.conversion_factor && this.conversionFactors?.dc2_length?.conversion_factor!="" ){
              payload.dc2length *= this.conversionFactors.dc2_length.conversion_factor;
            }
            if(this.conversionFactors?.hwdp_od?.conversion_factor && this.conversionFactors?.hwdp_od?.conversion_factor!="" ){
              payload.hwdpod *= this.conversionFactors.hwdp_od.conversion_factor;
            }
            if(this.conversionFactors?.hwdp_id?.conversion_factor && this.conversionFactors?.hwdp_id?.conversion_factor!="" ){
              payload.hwdpid *= this.conversionFactors.hwdp_id.conversion_factor;
            }
            if(this.conversionFactors?.hwdp_length?.conversion_factor && this.conversionFactors?.hwdp_length?.conversion_factor!="" ){
              payload.hwdplength *= this.conversionFactors.hwdp_length.conversion_factor;
            }
            if(this.conversionFactors?.dp_od?.conversion_factor && this.conversionFactors?.dp_od?.conversion_factor!="" ){
              payload.dpod *= this.conversionFactors.dp_od.conversion_factor;
            }
            if(this.conversionFactors?.dp_id?.conversion_factor && this.conversionFactors?.dp_id?.conversion_factor!="" ){
              payload.dpid *= this.conversionFactors.dp_id.conversion_factor;
            }
            if(this.conversionFactors?.dp_length?.conversion_factor && this.conversionFactors?.dp_length?.conversion_factor!="" ){
              payload.dplength *= this.conversionFactors.dp_length.conversion_factor;
            }
            if(this.conversionFactors?.casing_length?.conversion_factor && this.conversionFactors?.casing_length?.conversion_factor!="" ){
              payload.casinglength *= this.conversionFactors.casing_length.conversion_factor;
            }
            if(this.conversionFactors?.casing_id?.conversion_factor && this.conversionFactors?.casing_id?.conversion_factor!="" ){
              payload.casingid *= this.conversionFactors.casing_id.conversion_factor;
            }
            if(this.conversionFactors?.mud_density?.conversion_factor && this.conversionFactors?.mud_density?.conversion_factor!="" ){
              payload.muddensity *= this.conversionFactors.mud_density.conversion_factor;
            }
            if(this.conversionFactors?.plactic_viscosity?.conversion_factor && this.conversionFactors?.plactic_viscosity?.conversion_factor!="" ){
              payload.plasticViscosity *= this.conversionFactors.plactic_viscosity.conversion_factor;
            }
            if(this.conversionFactors?.tripp_speed?.conversion_factor && this.conversionFactors?.tripp_speed?.conversion_factor!="" ){
              payload.minPipeVelocity *= this.conversionFactors.tripp_speed.conversion_factor;
            }
            if(this.conversionFactors?.tripp_speed?.conversion_factor && this.conversionFactors?.tripp_speed?.conversion_factor!="" ){
              payload.maxPipeVelocity *= this.conversionFactors.tripp_speed.conversion_factor;
            }
        await axios
          .post(
            configjs.HOST_URL.DATASERVICES_URL + "swabsurgesolution/start",payload)
          .then((resp) => {
            this.graphData = resp.data;
            this.graphData.forEach(e => {
              if(this.conversionFactors?.trip_depth?.conversion_factor && this.conversionFactors?.trip_depth?.conversion_factor!="" ){
                e.depth /= this.conversionFactors.trip_depth.conversion_factor;
              }
              if(this.conversionFactors?.trip_depth?.conversion_factor && this.conversionFactors?.trip_depth?.conversion_factor!="" ){
                e.dbtm *= this.conversionFactors.trip_depth.conversion_factor;
              }
              e.swabSurgeList.forEach(swabSurgeList=>{
                if(this.conversionFactors?.tripp_speed?.conversion_factor && this.conversionFactors?.tripp_speed?.conversion_factor!="" ){
                    swabSurgeList.tripSpeed /= this.conversionFactors.tripp_speed.conversion_factor;
                }
                if(this.conversionFactors?.mud_density?.conversion_factor && this.conversionFactors?.mud_density?.conversion_factor!="" ){
                    swabSurgeList.swabAndSurgePressureinppg /= this.conversionFactors.mud_density.conversion_factor;
                }
              })
            });
            this.set3DGraph2Data();
          })
          .catch((err) => {
            this.set3DGraph2Data();
            console.error(err);
          });
      } else {
        this.set3DGraph2Data();
      }
    },
    set3DGraph1Data() {
      let result = {};
      this.graphData.map((d, index) => {
        if (this.$refs["theCheckbox1" + this.widgetId].checked == true) {
          if (index == 0) {
            d.swabSurgeList.map((swab) => {
              result[swab.depth + "@"] = [
                { x: 0, y: 0, data: {} },
                { x: d.speed, y: swab.swabAndSurgePressureinppg, data: swab },
              ];
            });
          } else {
            d.swabSurgeList.map((swab) => {
              result[swab.depth + "@"].push({
                x: d.speed,
                y: swab.swabAndSurgePressureinppg,
                data: swab,
              });
            });
          }
        } else {
          if (index == 0) {
            d.swabSurgeList.map((swab) => {
              result[swab.depth + "@"] = [
                { x: 0, y: 0, data: {} },
                { x: d.speed, y: swab.swabAndSurgePressure, data: swab },
              ];
            });
          } else {
            d.swabSurgeList.map((swab) => {
              result[swab.depth + "@"].push({
                x: d.speed,
                y: swab.swabAndSurgePressure,
                data: swab,
              });
            });
          }
        }
      });
      this.megaData2 = result;
      this.buildSVG1(this.widgetId);
    },
    set3DGraph2Data() {
      let result = {};
      this.graphData.map((d, index) => {
        if (this.$refs["theCheckbox1" + this.widgetId].checked == true) {
          if (index == 0) {
            d.swabSurgeList.map((swab) => {
              result[swab.tripSpeed + "@"] = [
                { x: 0, y: 0, data: {}, swab: {} },
                {
                  x: swab.swabAndSurgePressureinppg,
                  y: d.depth,
                  data: d,
                  swab: swab,
                },
              ];
            });
          } else {
            d.swabSurgeList.map((swab) => {
              result[swab.tripSpeed + "@"].push({
                x: swab.swabAndSurgePressureinppg,
                y: d.depth,
                data: d,
                swab: swab,
              });
            });
          }
        } else {
          if (index == 0) {
            d.swabSurgeList.map((swab) => {
              result[swab.tripSpeed + "@"] = [
                { x: 0, y: 0, data: {}, swab: {} },
                {
                  x: swab.swabAndSurgePressure,
                  y: d.depth,
                  data: d,
                  swab: swab,
                },
              ];
            });
          } else {
            d.swabSurgeList.map((swab) => {
              result[swab.tripSpeed + "@"].push({
                x: swab.swabAndSurgePressure,
                y: d.depth,
                data: d,
                swab: swab,
              });
            });
          }
        }
      });
      console.log("data 2 secrets : ", result);
      this.megaData = result;
      this.buildSVG2(this.widgetId);
    },
    set3DGraph3Data() {
      let result = [[{ x: 0, y: 0, data: {} }]];
      if (this.graph3Data != "") {
        if (this.graph3Data[0]?.swabSurgeData?.tripStatus === "TIH") {
          result = [[{ x: 0, y: 0, data: {} }]];
        } else if (this.graph3Data[0]?.swabSurgeData?.tripStatus === "TOOH") {
          this.graph3Data.sort((a, b) => a.dbtm - b.dbtm);
          result = [];
        }
      } else {
        result = [[{ x: 0, y: 0, data: {} }]];
      }

      this.graph3Data.map((d, index) => {
        if (this.realTime_psi_ppg) {
          if (d.swabSurgeData.tripStatus === "TIH") {
            result.push([
              {
                x: parseFloat(d.swabSurgeData.surgePressureinppg),
                y: d.dbtm,
                data: d,
              },
            ]);
          } else if (d.swabSurgeData.tripStatus === "TOOH") {
            result.push([
              {
                x: parseFloat(d.swabSurgeData.swabPressureinppg),
                y: d.dbtm,
                data: d,
              },
            ]);
          }
        } else {
          if (d.swabSurgeData.tripStatus === "TIH") {
            result.push([
              {
                x: parseFloat(d.swabSurgeData.surgePressure),
                y: d.dbtm,
                data: d,
              },
            ]);
          } else if (d.swabSurgeData.tripStatus === "TOOH") {
            result.push([
              {
                x: parseFloat(d.swabSurgeData.swabPressure),
                y: d.dbtm,
                data: d,
              },
            ]);
          }
        }
      });
      this.megaData3 = result;
      this.buildSVG3(this.widgetId);
    },
    async buildSVG1(widj, isCalledFromZoom) {
      //create axis

      let colors = ["green", "yellow", "red", "orange", "blue"];
      let dummy = [];
      this.static_graph_1_indicators = [];
      this.max = 0;
      let maxXValue = [];
      for (var a in this.megaData2) {
        this.static_graph_1_indicators.push({
          value: a.split("@")[0],
          color: colors[Object.keys(this.megaData2).indexOf(a)],
        });
        var filteredData2 = this.megaData2[a];
        let data = {
          width: 2,
          color: colors[Object.keys(this.megaData2).indexOf(a)],
          data: filteredData2,
        };
        dummy.push(data);
        maxXValue = [];
        const swabPress = filteredData2.map((object) => {
          maxXValue.push(object.x)
          return object.y;
        });
        this.max = Math.max(...swabPress);
      }
      this.maxX = maxXValue.length ? parseFloat(Math.max(...maxXValue)) + 10 : 10;
      if (this.max != 0 || this.max != "") {
        this.maxY = this.max;
      } else {
        this.maxY = 10;
      }
      this.maxY = this.maxY + this.maxY * 0.1;
      this.config = {
        minX: this.minX,
        minY: this.minY,
        maxX: this.maxX,
        maxY: this.maxY,
        unitsPerTickX: 1,
        unitsPerTickY: 1,
        yTitle: this.$refs["theCheckbox1" + this.widgetId]?.checked
          ? "Surge/Swab EMW (ppg)"
          : "Surge/Swab Pressure (psi)",
        xTitle: "Trip Speed (ft/min)",
      };
      if(this.conversionFactors?.tripp_speed?.unit && this.conversionFactors?.tripp_speed?.unit!="" ){ 
        this.config.xTitle=`Trip Speed (${this.conversionFactors.tripp_speed.unit})`;   
      }
      this.dummy_data = dummy;
      return true;
    },
    async buildSVG2(widj, isCalledFromZoom) {
      let colors = ["green", "yellow", "red", "orange", "blue"];
      this.static_graph_2_indicators = [];
      let dummy = [];
      let maxYValue = [];
      for (let a in this.megaData) {
        this.static_graph_2_indicators.push({
          value: a.split("@")[0],
          color: colors[Object.keys(this.megaData).indexOf(a)],
        });
        let filteredData = this.megaData[a];
        let data = {
          width: 2,
          color: colors[Object.keys(this.megaData).indexOf(a)],
          data: filteredData,
        };
        dummy.push(data);
        maxYValue = [];
        const swabPress = filteredData.map((object) => {
          maxYValue.push(object.y)
          return object.y;
        });
        const swabPressX = filteredData.map((object) => {
          return object.x;
        });
        this.max = Math.max(...swabPress);
        this.max_x = Math.max(...swabPressX);
        console.log(" max of max : ~~~~~~~ 2 ~~~ ", this.max);
      }
      this.maxY = maxYValue.length ? parseFloat(Math.max(...maxYValue)) : 10;
      if (!Object.keys(this.megaData).length) {
        this.max_x = 10;
      }
      if (this.max != 0 || this.max != "") {
        this.maxX = this.max_x + parseFloat(this.max_x) * 0.1;
      } else {
        this.maxX = 10;
      }
      this.maxY = parseFloat(this.maxY) + parseFloat(this.maxY) * 0.1;

      this.config_plot_2 = {
        minX: this.minX,
        minY: this.minY,
        maxX: this.maxX,
        maxY: this.maxY,
        unitsPerTickX: 1,
        unitsPerTickY: 1,
        yTitle: "Trip Depth (ft)",
        xTitle: this.$refs["theCheckbox1" + this.widgetId]?.checked
          ? "Surge/Swab EMW (ppg)"
          : "Surge/Swab Pressure (psi)",
      };
      if(this.conversionFactors?.trip_depth?.unit && this.conversionFactors?.trip_depth?.unit!="" ){ 
        this.config_plot_2.yTitle=`Trip Depth (${this.conversionFactors.trip_depth.unit})`;       
      }
      this.data_plot_2 = dummy;
      return true;

      // alert("buildsvgg")
      var margin = {
          top: 10,
          right: 50,
          bottom: 50,
          left: 10,
        },
        width = this.settingWidth() || 200,
        height = this.height - margin.top - margin.bottom - 33 - 20 - 50;
      let labelFontSize = 12;

      //create base svg
      svg = select("#tvdTrajectoryTrend2" + widj);
      if (svg) {
        svg.selectAll("*").remove();
        svg = "";
      } else {
        let sweb = document.createElement("div");
        sweb.setAttribute("id", +"tvdTrajectoryTrend2" + widj);
      }
      svg = select("#tvdTrajectoryTrend2" + widj)
        .append("svg")
        .attr("width", width + margin.left + margin.right + 10)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        // .attr("id", "tvdTrajectoryTrend2" + 1)
        .attr("class", "g-class")
        .attr("transform", `translate(${margin.left + 50}, ${margin.top})`)
        .attr("height", height + margin.top + margin.bottom);

      //structure for trajectory
      if (Object.keys(this.megaData) != "") {
        let swabPress;
        for (var a in this.megaData) {
          var filteredData = this.megaData[a];
          swabPress = filteredData.map((object) => {
            return object.y;
          });
        }
        this.max = Math.max(...swabPress) + 10;
      } else {
        this.max = "";
      }

      this.maxY = this.trip_Depth_maxy;
      if (this.max != 0 || this.max != "") {
        this.maxX = this.max;
      } else {
        this.maxX = 10;
      }
      this.maxY = parseFloat(this.maxY) + parseFloat(this.maxY) * 0.1;
      //create axis
      if (this.isZoomFeature && this.extent_static_graph_2[0]) {
        var x = scaleLinear()
          .domain([
            this.minXZoom_static_grph_2 || this.minX,
            this.maxXZoom_static_grph_2 || this.maxX,
          ])
          .range([0, width]);
        var y = scaleLinear()
          .domain([
            this.maxYZoom_static_grph_2 || this.maxY || 10,
            this.minYZoom_static_grph_2 || this.minY,
          ])
          .range([height, 0]); // Axis starting range

        if (isCalledFromZoom) {
          this.minXZoom_static_grph_2 = x.invert(
            this.extent_static_graph_2[0][0]
          );
          this.maxXZoom_static_grph_2 = x.invert(
            this.extent_static_graph_2[1][0]
          );
          this.minYZoom_static_grph_2 = y.invert(
            this.extent_static_graph_2[0][1]
          );
          this.maxYZoom_static_grph_2 = y.invert(
            this.extent_static_graph_2[1][1]
          );
        }

        x.domain([this.minXZoom_static_grph_2, this.maxXZoom_static_grph_2]);
        y.domain([this.maxYZoom_static_grph_2, this.minYZoom_static_grph_2]);
      } else {
        this.minXZoom_static_grph_2 = null;
        this.maxXZoom_static_grph_2 = null;
        this.minYZoom_static_grph_2 = null;
        this.maxYZoom_static_grph_2 = null;

        this.maxY = this.trip_Depth_maxy || 10;

        if (this.max != 0 || this.max != "") {
          this.maxX = this.max;
        } else {
          this.maxX = 10;
        }
        this.maxY = parseFloat(this.maxY) + parseFloat(this.maxY) * 0.1;
        var x = scaleLinear().domain([this.minX, this.maxX]).range([0, width]);
        var y = scaleLinear().domain([this.maxY, this.minY]).range([height, 0]); // Axis starting range
      }

      var xAxis = axisBottom(x).ticks();
      var yAxis = axisLeft(y).ticks();

      //create grid axis
      const xAxisGrid = axisBottom(x).tickSize(-height).tickFormat("").ticks();
      const yAxisGrid = axisLeft(y).tickSize(-width).tickFormat("").ticks();

      var clip = svg
        .append("defs")
        .append("svg:clipPath")
        .attr("id", "clip")
        .append("svg:rect")
        .attr("width", width)
        .attr("height", height)
        .attr("x", 0)
        .attr("y", 0);
      // Create grids.
      svg
        .append("g")
        .attr("class", "x axis-grid")
        .attr("transform", "translate(0," + height + ")")
        .style("color", this.gridColor)
        .style("fill", this.gridBackground)
        .attr("stroke-opacity", 0.4)
        .call(xAxisGrid);
      svg
        .append("g")
        .attr("class", "y axis-grid")
        .style("color", this.gridColor)
        .attr("stroke-opacity", 0.4)
        .call(yAxisGrid);

      // Create axes.
      svg
        .append("g")
        .attr("class", "xAxis")
        .attr("transform", "translate(0," + height + ")")
        .style("font-size", this.xAxisLabelSize)
        .call(xAxis);
      svg
        .selectAll(".xAxis")
        .selectAll("text")
        .attr("fill", this.yAxisLabel)
        .style("transform", "translate(0px, 0px) rotate(320deg)")
        .style("text-anchor", "end");
      svg
        .append("g")
        .attr("class", "yAxis")
        .style("font-size", this.yAxisLabelSize)
        .call(yAxis);
      svg.selectAll(".yAxis").selectAll("text").attr("fill", this.yAxisLabel);

      if (this.isZoomFeature)
        var brushB = brush() // Add the brush feature using the brush function
          .extent([
            [0, 0],
            [width, height],
          ]) // initialise the brush area: start at 0,0 and finishes at width,height: it means I select the whole graph area
          .on("start", this.updateStart)
          .on("end", (event) => {
            this.updateChart(event, "tvdTrajectoryTrend2" + widj);
          });

      let lineB = svg.append("g").attr("clip-path", "url(#clip)");
      // svg = select("#tvdTrajectoryTrend2" + 1)
      svg
        .append("text")
        .attr("class", "x label")
        .attr("text-anchor", "end")
        .attr("x", width / 2 + margin.right + 25)
        .attr("y", height + 44)
        .style("fill", this.xAxisLabel)
        .style("font-size", labelFontSize)
        .text(
          this.$refs["theCheckbox1" + this.widgetId]?.checked
            ? "Surge/Swab EMW (ppg)"
            : "Surge/Swab Pressure (psi)"
        );

      // svg = select("#tvdTrajectoryTrend2" + 1)
      svg
        .append("text")
        .attr("class", "y label")
        .attr("text-anchor", "end")
        .attr(
          "dy",
          this.y_axis_label_position(
            this.isZoomFeature && this.extent_static_graph_2[0]
              ? this.maxYZoom_static_grph_2
              : this.maxY
          )
        )
        .attr("dx", function () {
          return -(height / 2 - margin.bottom + 10);
        })
        .attr("transform", "rotate(-90)")
        .style("fill", this.xAxisLabel)
        .style("font-size", labelFontSize)
        .text("Trip depth (ft)");

      // let colors = ["green", "yellow", "red", "orange", "blue"];
      this.static_graph_2_indicators = [];
      for (var a in this.megaData) {
        this.static_graph_2_indicators.push({
          value: a.split("@")[0],
          color: colors[Object.keys(this.megaData).indexOf(a)],
        });
        var filteredData = this.megaData[a];
        lineB // WILL PREVENT OVERLAPPING OF GRAPH WHILE ZOOM, ONLY IF USES "lineB" intead of "svg"
          .append("path")
          .datum(filteredData)
          .attr("fill", "none")
          .attr("stroke", colors[Object.keys(this.megaData).indexOf(a)])
          .attr("stroke-width", 3)
          .attr(
            "d",
            line()
              .x(function (d) {
                return x(d.y);
              })
              .y(function (d) {
                return y(d.x);
              })
          );

        const swabPress = filteredData.map((object) => {
          return object.y;
        });
        this.max = Math.max(...swabPress);
      }
      if (this.isZoomFeature) {
        lineB.append("g").attr("class", "brush").call(brushB);
      }
      for (var a in this.megaData) {
        if (this.isShow) {
          var filteredData = this.megaData[a];
          for (let eachObj of filteredData) {
            if (Object.keys(eachObj.data).length) {
              lineB
                .append("circle")
                .data([eachObj])
                .attr("cx", function (d) {
                  return x(d.y);
                })
                .attr("cy", function (d) {
                  return y(d.x);
                })
                .attr("r", 5)
                .attr("fill", "cyan")
                .style("opacity", "0.7")
                .on("mouseover", (event, d) => {
                  if (this.isShow) {
                    return this.show_static_tool_tip_graph_2(event, d);
                  }
                  return;
                })
                .on("mouseout", (d) => {
                  return this.hide_static_tool_tip_graph_2();
                });
            }
          }
        }
      }
    },
    async buildSVG3(widj, isCalledFromZoom) {
      let dummy = [];
      let arr_obj = [];

      try {
        let for_x_range = [];
        let for_y_range = [];
        this.megaData3.map((object) => {
          arr_obj.push(object[0]);
          !isNaN(object[0].x) && for_x_range.push(parseFloat(object[0].x));
          !isNaN(object[0].y) && for_y_range.push(parseFloat(object[0].y));
        });
        let data = {
          width: 2,
          color: "red",
          data: arr_obj,
        };
        dummy.push(data);
        this.max = for_x_range.length && Math.max(...for_x_range) + 10;
        this.maxY = for_y_range.length ? Math.max(...for_y_range) + 10 : 10;
      } catch (err) {
        this.max = "";
        console.log(err);
      }

      if (this.max != 0 || this.max != "") {
        this.maxX = this.max;
      } else {
        this.maxX = 10;
      }
      this.maxY = parseFloat(this.maxY) + parseFloat(this.maxY) * 0.1;

      this.config_plot_3 = {
        minX: this.minX,
        minY: this.minY,
        maxX: this.maxX,
        maxY: this.maxY,
        unitsPerTickX: 1,
        unitsPerTickY: 1,
        yTitle: "Trip Depth (ft)",
        xTitle: this.realTime_psi_ppg
          ? "Surge/Swab EMW (ppg)"
          : "Surge/Swab Pressure (psi)",
      };
      if (this.realTime_psi_ppg) {
        if (this.xAutoScalePpg && this.yAutoScalePpg) {
          this.config_plot_3.minX = this.xRangeStartPpg;
          this.config_plot_3.maxX = this.xRangeEndPpg;
          this.config_plot_3.minY = this.yRangeStartPpg;
          this.config_plot_3.maxY = this.yRangeEndPpg;
        } else if (this.xAutoScalePpg) {
          this.config_plot_3.minX = this.xRangeStartPpg;
          this.config_plot_3.maxX = this.xRangeEndPpg;
          this.config_plot_3.minY = this.minY;
          this.config_plot_3.maxY = this.maxY;
        } else if (this.yAutoScalePpg) {
          this.config_plot_3.minX = this.minX;
          this.config_plot_3.maxX = this.maxX;
          this.config_plot_3.minY = this.yRangeStartPpg;
          this.config_plot_3.maxY = this.yRangeEndPpg;
        } else {
          this.config_plot_3.minX = this.minX;
          this.config_plot_3.maxX = this.maxX;
          this.config_plot_3.minY = this.minY;
          this.config_plot_3.maxY = this.maxY;
        }
      } else {
        if (this.xAutoScale && this.yAutoScale) {
          this.config_plot_3.minX = this.xRangeStart;
          this.config_plot_3.maxX = this.xRangeEnd;
          this.config_plot_3.minY = this.yRangeStart;
          this.config_plot_3.maxY = this.yRangeEnd;
        } else if (this.xAutoScale) {
          this.config_plot_3.minX = this.xRangeStart;
          this.config_plot_3.maxX = this.xRangeEnd;
          this.config_plot_3.minY = this.minY;
          this.config_plot_3.maxY = this.maxY;
        } else if (this.yAutoScale) {
          this.config_plot_3.minX = this.minX;
          this.config_plot_3.maxX = this.maxX;
          this.config_plot_3.minY = this.yRangeStart;
          this.config_plot_3.maxY = this.yRangeEnd;
        } else {
          this.config_plot_3.minX = this.minX;
          this.config_plot_3.maxX = this.maxX;
          this.config_plot_3.minY = this.minY;
          this.config_plot_3.maxY = this.maxY;
        }
        if(this.conversionFactors?.trip_depth?.unit && this.conversionFactors?.trip_depth?.unit!="" ){ 
        this.config_plot_3.yTitle=`Trip Depth (${this.conversionFactors.trip_depth.unit})`;      
        } 
      }
      this.data_plot_3 = dummy;
      return true;

      var margin = {
          top: 10,
          right: 50,
          bottom: 50,
          left: 10,
        },
        width = this.settingWidth() || 200,
        height = this.height - margin.top - margin.bottom - 33 - 20 - 50;
      let labelFontSize = 12;

      //create base svg
      svg = select("#tvdTrajectoryTrend3" + widj);
      if (svg) {
        svg.selectAll("*").remove();
        svg = "";
      } else {
        let sweb = document.createElement("div");
        sweb.setAttribute("id", +"tvdTrajectoryTrend3" + widj);
      }
      svg = select("#tvdTrajectoryTrend3" + widj)
        .append("svg")
        .attr("width", width + margin.left + margin.right + 10)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        // .attr("id", "tvdTrajectoryTrend3" + 1)
        .attr("class", "g-class")
        .attr("transform", `translate(${margin.left + 50}, ${margin.top})`)
        .attr("height", height + margin.top + margin.bottom);

      try {
        let for_x_range = [];
        let for_y_range = [];
        this.megaData3.map((object) => {
          !isNaN(object[0].y) && for_x_range.push(parseFloat(object[0].y));
          !isNaN(object[0].x) && for_y_range.push(parseFloat(object[0].x));
        });
        this.max = for_x_range.length && Math.max(...for_x_range) + 10;
        this.maxY = for_y_range.length ? Math.max(...for_y_range) + 10 : 10;
      } catch (err) {
        this.max = "";
        console.log(err);
      }

      if (this.max != 0 || this.max != "") {
        this.maxX = this.max;
      } else {
        this.maxX = 10;
      }
      this.maxY = parseFloat(this.maxY) + parseFloat(this.maxY) * 0.1;
      //create axis
      if (this.isZoomFeature && this.extent_real_time_graph[0]) {
        // var x = scaleLinear()
        //   .domain([
        //     this.minXZoom_real_time_grph || this.minX,
        //     this.maxXZoom_real_time_grph || this.maxX,
        //   ])
        //   .range([0, width]);
        // var y = scaleLinear()
        //   .domain([
        //     this.maxYZoom_real_time_grph || this.maxY || 10,
        //     this.minYZoom_real_time_grph || this.minY,
        //   ])
        //   .range([height, 0]); // Axis starting range

        if (this.realTime_psi_ppg) {
          if (this.xAutoScalePpg && this.yAutoScalePpg) {
            var x = scaleLinear()
              .domain([
                this.minXZoom_real_time_grph || this.xRangeStartPpg,
                this.maxXZoom_real_time_grph || this.xRangeEndPpg,
              ])
              .range([0, width]);
            var y = scaleLinear()
              .domain([
                this.maxYZoom_real_time_grph || this.yRangeEndPpg || 10,
                this.minYZoom_real_time_grph || this.yRangeStartPpg,
              ])
              .range([height, 0]);
          } else if (this.xAutoScalePpg) {
            var x = scaleLinear()
              .domain([
                this.minXZoom_real_time_grph || this.xRangeStartPpg,
                this.maxXZoom_real_time_grph || this.xRangeEndPpg,
              ])
              .range([0, width]);
            var y = scaleLinear()
              .domain([
                this.maxYZoom_real_time_grph || this.maxY || 10,
                this.minYZoom_real_time_grph || this.minY,
              ])
              .range([height, 0]);
          } else if (this.yAutoScalePpg) {
            var x = scaleLinear()
              .domain([
                this.minXZoom_real_time_grph || this.minX,
                this.maxXZoom_real_time_grph || this.maxX,
              ])
              .range([0, width]);
            var y = scaleLinear()
              .domain([
                this.maxYZoom_real_time_grph || this.yRangeEndPpg || 10,
                this.minYZoom_real_time_grph || this.yRangeStartPpg,
              ])
              .range([height, 0]);
          } else {
            var x = scaleLinear()
              .domain([
                this.minXZoom_real_time_grph || this.minX,
                this.maxXZoom_real_time_grph || this.maxX,
              ])
              .range([0, width]);
            var y = scaleLinear()
              .domain([
                this.maxYZoom_real_time_grph || this.maxY || 10,
                this.minYZoom_real_time_grph || this.minY,
              ])
              .range([height, 0]); // Axis starting range
          }
        } else {
          if (this.xAutoScale && this.yAutoScale) {
            var x = scaleLinear()
              .domain([
                this.minXZoom_real_time_grph || this.xRangeStart,
                this.maxXZoom_real_time_grph || this.xRangeEnd,
              ])
              .range([0, width]);
            var y = scaleLinear()
              .domain([
                this.maxYZoom_real_time_grph || this.yRangeEnd || 10,
                this.minYZoom_real_time_grph || this.yRangeStart,
              ])
              .range([height, 0]);
          } else if (this.xAutoScale) {
            var x = scaleLinear()
              .domain([
                this.minXZoom_real_time_grph || this.xRangeStart,
                this.maxXZoom_real_time_grph || this.xRangeEnd,
              ])
              .range([0, width]);
            var y = scaleLinear()
              .domain([
                this.maxYZoom_real_time_grph || this.maxY || 10,
                this.minYZoom_real_time_grph || this.minY,
              ])
              .range([height, 0]);
          } else if (this.yAutoScale) {
            var x = scaleLinear()
              .domain([
                this.minXZoom_real_time_grph || this.minX,
                this.maxXZoom_real_time_grph || this.maxX,
              ])
              .range([0, width]);
            var y = scaleLinear()
              .domain([
                this.maxYZoom_real_time_grph || this.yRangeEnd || 10,
                this.minYZoom_real_time_grph || this.yRangeStart,
              ])
              .range([height, 0]);
          } else {
            var x = scaleLinear()
              .domain([
                this.minXZoom_real_time_grph || this.minX,
                this.maxXZoom_real_time_grph || this.maxX,
              ])
              .range([0, width]);
            var y = scaleLinear()
              .domain([
                this.maxYZoom_real_time_grph || this.maxY || 10,
                this.minYZoom_real_time_grph || this.minY,
              ])
              .range([height, 0]); // Axis starting range
          }
        }

        if (isCalledFromZoom) {
          this.minXZoom_real_time_grph = x.invert(
            this.extent_real_time_graph[0][0]
          );
          this.maxXZoom_real_time_grph = x.invert(
            this.extent_real_time_graph[1][0]
          );
          this.minYZoom_real_time_grph = y.invert(
            this.extent_real_time_graph[0][1]
          );
          this.maxYZoom_real_time_grph = y.invert(
            this.extent_real_time_graph[1][1]
          );
        }
        x.domain([this.minXZoom_real_time_grph, this.maxXZoom_real_time_grph]);
        y.domain([this.maxYZoom_real_time_grph, this.minYZoom_real_time_grph]);
      } else {
        this.minXZoom_real_time_grph = null;
        this.maxXZoom_real_time_grph = null;
        this.minYZoom_real_time_grph = null;
        this.maxYZoom_real_time_grph = null;

        // this.maxY = 10;

        try {
          let for_x_range = [];
          let for_y_range = [];
          this.megaData3.map((object) => {
            !isNaN(object[0].y) && for_x_range.push(parseFloat(object[0].y));
            !isNaN(object[0].x) && for_y_range.push(parseFloat(object[0].x));
          });
          this.max = for_x_range.length && Math.max(...for_x_range) + 10;
          this.maxY = for_y_range.length ? Math.max(...for_y_range) + 10 : 10;
        } catch (err) {
          this.max = "";
          console.log(err);
        }

        if (this.max != 0 || this.max != "") {
          this.maxX = this.max;
        } else {
          this.maxX = 10;
        }
        this.maxY = parseFloat(this.maxY) + parseFloat(this.maxY) * 0.1;
        if (this.realTime_psi_ppg) {
          if (this.xAutoScalePpg && this.yAutoScalePpg) {
            var x = scaleLinear()
              .domain([this.xRangeStartPpg, this.xRangeEndPpg])
              .range([0, width]);
            var y = scaleLinear()
              .domain([this.yRangeEndPpg, this.yRangeStartPpg])
              .range([height, 0]);
          } else if (this.xAutoScalePpg) {
            var x = scaleLinear()
              .domain([this.xRangeStartPpg, this.xRangeEndPpg])
              .range([0, width]);
            var y = scaleLinear()
              .domain([this.maxY, this.minY])
              .range([height, 0]);
          } else if (this.yAutoScalePpg) {
            var x = scaleLinear()
              .domain([this.minX, this.maxX])
              .range([0, width]);
            var y = scaleLinear()
              .domain([this.yRangeEndPpg, this.yRangeStartPpg])
              .range([height, 0]);
          } else {
            var x = scaleLinear()
              .domain([this.minX, this.maxX])
              .range([0, width]);
            var y = scaleLinear()
              .domain([this.maxY, this.minY])
              .range([height, 0]); // Axis starting range
          }
        } else {
          if (this.xAutoScale && this.yAutoScale) {
            var x = scaleLinear()
              .domain([this.xRangeStart, this.xRangeEnd])
              .range([0, width]);
            var y = scaleLinear()
              .domain([this.yRangeEnd, this.yRangeStart])
              .range([height, 0]);
          } else if (this.xAutoScale) {
            var x = scaleLinear()
              .domain([this.xRangeStart, this.xRangeEnd])
              .range([0, width]);
            var y = scaleLinear()
              .domain([this.maxY, this.minY])
              .range([height, 0]);
          } else if (this.yAutoScale) {
            var x = scaleLinear()
              .domain([this.minX, this.maxX])
              .range([0, width]);
            var y = scaleLinear()
              .domain([this.yRangeEnd, this.yRangeStart])
              .range([height, 0]);
          } else {
            var x = scaleLinear()
              .domain([this.minX, this.maxX])
              .range([0, width]);
            var y = scaleLinear()
              .domain([this.maxY, this.minY])
              .range([height, 0]); // Axis starting range
          }
        }
      }

      // svg = select('#tvdTrajectoryTrend3' + 1)
      //         .append("path")
      //         .datum(arr_object)
      //         .attr("fill", "none")
      //         .attr("stroke", "red")
      //         .attr("stroke-width", 3)
      //         .attr(
      //             "d",
      //             line()
      //                 .x(function (d) {
      //                     return x(d.y);
      //                 })
      //                 .y(function (d) {

      //                     return y(d.x);
      //                 })
      //         )
      var xAxis = axisBottom(x).ticks();
      var yAxis = axisLeft(y).ticks();

      //create grid axis
      const xAxisGrid = axisBottom(x).tickSize(-height).tickFormat("").ticks();
      const yAxisGrid = axisLeft(y).tickSize(-width).tickFormat("").ticks();

      // var clip = select("#tvdTrajectoryTrend3" + 1)
      var clip = svg
        .append("defs")
        .append("svg:clipPath")
        .attr("id", "clip")
        .append("svg:rect")
        .attr("width", width)
        .attr("height", height)
        .attr("x", 0)
        .attr("y", 0);
      // Create grids.
      // svg = select("#tvdTrajectoryTrend3" + 1)
      svg
        .append("g")
        .attr("class", "x axis-grid")
        .attr("transform", "translate(0," + height + ")")
        .style("color", this.gridColor)
        .style("fill", this.gridBackground)
        .attr("stroke-opacity", 0.4)
        .call(xAxisGrid);
      // svg = select("#tvdTrajectoryTrend3" + 1)
      svg
        .append("g")
        .attr("class", "y axis-grid")
        .style("color", this.gridColor)
        .attr("stroke-opacity", 0.4)
        .call(yAxisGrid);

      // Create axes.
      // svg = select("#tvdTrajectoryTrend3" + 1)
      svg
        .append("g")
        .attr("class", "xAxis")
        .attr("transform", "translate(0," + height + ")")
        .style("font-size", this.xAxisLabelSize)
        .call(xAxis);
      svg
        .selectAll(".xAxis")
        .selectAll("text")
        .attr("fill", this.yAxisLabel)
        .style("transform", "translate(0px, 0px) rotate(320deg)")
        .style("text-anchor", "end");
      // svg = select("#tvdTrajectoryTrend3" + 1)
      svg
        .append("g")
        .attr("class", "yAxis")
        .style("font-size", this.yAxisLabelSize)
        .call(yAxis);
      svg.selectAll(".yAxis").selectAll("text").attr("fill", this.yAxisLabel);

      if (this.isZoomFeature)
        var brushB = brush() // Add the brush feature using the brush function
          .extent([
            [0, 0],
            [width, height],
          ]) // initialise the brush area: start at 0,0 and finishes at width,height: it means I select the whole graph area
          .on("start", this.updateStart)
          .on("end", (event) => {
            this.updateChart(event, "tvdTrajectoryTrend3" + widj);
          });

      let lineB = svg.append("g").attr("clip-path", "url(#clip)");

      // svg = select("#tvdTrajectoryTrend3" + 1)
      svg
        .append("text")
        .attr("class", "x label")
        .attr("text-anchor", "end")
        .attr("x", width / 2 + margin.right + 25)
        .attr("y", height + 44)
        .style("fill", this.xAxisLabel)
        .style("font-size", labelFontSize)
        .text(
          this.realTime_psi_ppg
            ? "Surge/Swab EMW (ppg)"
            : "Surge/Swab Pressure (psi)"
        );

      // svg = select("#tvdTrajectoryTrend3" + 1)
      svg
        .append("text")
        .attr("class", "y label")
        .attr("text-anchor", "end")
        .attr(
          "dy",
          this.y_axis_label_position(
            this.isZoomFeature && this.extent_real_time_graph[0]
              ? this.maxYZoom_real_time_grph
              : this.maxY
          )
        )
        .attr("dx", function () {
          return -(height / 2 - margin.bottom + 10);
        })
        .attr("transform", "rotate(-90)")
        .style("fill", this.xAxisLabel)
        .style("font-size", labelFontSize)
        .text("Trip depth (ft)");

      // svg = select("#tvdTrajectoryTrend3" + 1);

      // WROTE THIS LOOP TO SHOW DOTS ON TOP OF LINE
      let arr_object = [];
      for (var arr of this.megaData3) {
        arr_object.push(arr[0]);
      }
      lineB
        .append("path")
        .datum(arr_object)
        .attr("fill", "none")
        .attr("stroke", "red")
        .attr("stroke-width", 3)
        .attr(
          "d",
          line()
            .x(function (d) {
              return x(d.y);
            })
            .y(function (d) {
              return y(d.x);
            })
        );

      if (this.isZoomFeature) {
        lineB.append("g").attr("class", "brush").call(brushB);
      }

      // let arr_object = [];
      for (var arr of this.megaData3) {
        var filteredData = arr;
        // arr_object.push(arr[0]);
        if (this.isShow) {
          if (Object.keys(arr[0].data).length) {
            lineB
              .append("circle")
              .data(filteredData)
              .attr("cx", function (d) {
                return x(d.y);
              })
              .attr("cy", function (d) {
                return y(d.x);
              })
              .attr("r", 5)
              .attr("fill", "cyan")
              .on("click", (event, d) => {
                return this.realTime_show_right_side_bar(d.data);
              })
              .on("mouseover", (event, d) => {
                if (this.isShow) {
                  return this.show_real_time_tool_tip(event, d.data);
                }
                return;
              })
              .on("mouseout", (d) => {
                return this.hide_real_time_tool_tip();
              });
          }
        }
      }
    },
    y_axis_label_position(val) {
      if (val.toString().includes(".")) {
        val = parseFloat(parseFloat(val).toFixed(2));
      }
      if (val != "" && val !== null) {
        if (val.toString().length <= 2) {
          return "-1.7em";
        } else if (val.toString().length == 3) {
          return "-2.5em";
        } else {
          return "-3.5em";
        }
      } else {
        return "-1.7em";
      }
    },

    dark() {
      this.$emit("dark");
    },
    getRandomColor() {
      // alert("Colro Dpeb calling")
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    light() {
      this.$emit("light");
    },
    async zoom_toggle(id) {
      this.zoom_checked = !this.zoom_checked;
      this.zoomOnOff(id);
    },
    rtCheckbox(e) {
      this.rtToggle(
        this.toggleValue,
        this.planid,
        this.bha_id,
        this.sourceid,
        e.target.checked
      );
    },
    async updateChart(e, svgId) {
      // alert("Update Chart werty")
      // this.extent = e.selection;
      this.isZoomed = true;
      if (e.selection) {
        if (
          // e.sourceEvent.target.closest(".g-class").id === "tvdTrajectoryTrend1"
          svgId ===
          "tvdTrajectoryTrend" + this.widgetId
        ) {
          this.extent = e.selection;
          this.buildSVG1(this.widgetId, true);
        } else if (
          // e.sourceEvent.target.closest(".g-class").id === "tvdTrajectoryTrend21"
          svgId ===
          "tvdTrajectoryTrend2" + this.widgetId
        ) {
          this.extent_static_graph_2 = e.selection;
          this.buildSVG2(this.widgetId, true);
        } else if (
          // e.sourceEvent.target.closest(".g-class").id === "tvdTrajectoryTrend31"
          svgId ===
          "tvdTrajectoryTrend3" + this.widgetId
        ) {
          this.extent_real_time_graph = e.selection;
          this.buildSVG3(this.widgetId, true);
          if (this.ss_trigger_clicked_swabsurgeName) {
            this.from_Depth = this.minYZoom_real_time_grph;
            this.to_Depth = this.maxYZoom_real_time_grph;
            this.ss_trigger_list_click(
              this.ss_trigger_clicked_swabsurgeName,
              true
            );
          }

          // if (this.ss_trigger_clicked_swabsurgeName) {
          //   this.from_Depth = null;
          //   this.to_Depth = null;
          //   this.show_popup_on_zoom = true;
          // }
        }
      }
    },
    updateStart(event) {
      event.sourceEvent.stopPropagation();
    },
    async dblClick(graph_id) {
      this.$store.dispatch("rect/clickedGraph", {
        value: graph_id,
      });
      this.$emit("to_openProperty", this.rect_index, this.rect_data);
      // await this.check_propty_bar_hide_left_bar();
    },
    runfn() {
      if (this.isLoading == false) {
        this.runInfoPopup = !this.runInfoPopup;
      }
      if (this.isLoading == true && !this.rerun_after_pause) {
        this.pauseInfoPopup = !this.pauseInfoPopup;
      }
      if (this.isLoading == true && this.rerun_after_pause) {
        this.pauseInfoPopup = true;
        this.runInfoPopup = false;
      }
      // if (this.wellId !== null && this.wellboreId !== null) {
      //     this.getData();
      // }
    },
    pausefn() {
      this.isLoadings = !this.isLoadings;
      this.isPause = !this.isPause;
    },
    cancel() {
      this.runInfoPopup = true;
      this.modeSelected = "select";
      this.pauseInfoPopup = true;
      this.savePopup = true;
    },
    save() {
      if (
        this.pipelength &&
        this.statusSelected != "select" &&
        this.modeSelected != "select"
      ) {
        this.pauseOrStop = "Pause / Stop";
        this.runInfoPopup = !this.runInfoPopup;
        // this.action = "RUN";
        if (this.swabName != "") {
          this.isLoading = true;
          this.isRun = false;
          this.postData();
        } else {
          this.action = "RUN";
          this.savePopup = !this.savePopup;
        }
      } else {
        this.$toast.clear();
        this.$toast.error(`Please fill all the fields`, {
          duration: "2000",
          position: "top",
          pauseOnHover: true,
        });
      }
    },
    Ok() {
      this.savePopup = !this.savePopup;
      this.postData();
    },
    yes() {
      this.pauseInfoPopup = true;
      this.runInfoPopup = false;
      this.action = "PAUSE";
      this.rerun_after_pause = false;
      // this.postData();
    },
    no() {
      this.pauseOrStopPopup = false;
      this.pauseInfoPopup = true;
      // this.postData();
    },
    Nofn() {
      if (!this.show_pause_stop_btn) {
        this.pauseOrStopPopup = true;
      } else {
        this.pauseInfoPopup = false;
        this.pauseOrStopPopup = true;
      }
      // this.postData();
    },
    stop() {
      this.pauseOrStopPopup = true;
      // this.pauseOrStop = 'Run'
      this.action = "STOP";
      // this.isLoading = false;
      // this.isRun = true;
      this.postData();
    },
    tripstatus(event, field) {
      this.dispatch_into_rect_rects(event.target.value, field);
      if (this.selected_trip_statues.toUpperCase() == "TIH") {
        this.selected_trip_status = 5;
      }
      if (this.selected_trip_statues.toUpperCase() == "TOOH") {
        this.selected_trip_status = 6;
      }
    },
    change_muddensity(event, field) {
      this.dispatch_into_rect_rects(event.target.value, field);
    },
    download() {
      const a = document.getElementById("download_screenshot" + this.widgetId);
      a.setAttribute("download", "screenshot.png");
      a.setAttribute("href", this.capturedImage);
      a.click();
    },
    // data = [{}], => real time stream {} => merge =>
    close() {
      this.show_print_popup = false;
    },
    speedMin(e) {
      if(e){
        this.commonDispatcher(e.target.value, 'speed_min')
      }
      if (this.speed_min != "" && this.pipe_length != "") {
        // this.stand_speed_min = this.speed_min / this.pipe_length;
        this.commonDispatcher(this.speed_min / this.pipe_length, 'stand_speed_min')
      } else {
        // this.stand_speed_min = "";
        this.commonDispatcher('', 'stand_speed_min')
      }
    },
    speedMax(e) {
      if(e){
        this.commonDispatcher(e.target.value, 'speed_max')
      }
      if (this.speed_max != "" && this.pipe_length != "") {
        // this.stand_speed_max = this.speed_max / this.pipe_length;
        this.commonDispatcher(this.speed_max / this.pipe_length, 'stand_speed_max')
      } else {
        // this.stand_speed_max = "";
        this.commonDispatcher('', 'stand_speed_max')
      }
    },
    pipelengchange(e) {
      if(e){
        this.commonDispatcher(e.target.value, 'pipe_length')
      }
      if (this.pipe_length != "") {
        if (this.speed_max != "") {
          this.speedMax();
        }
        if (this.speed_min != "") {
          this.speedMin();
        }
      } else {
        // this.stand_speed_max = "";
        // this.stand_speed_min = "";
        this.commonDispatcher('', 'stand_speed_min')
        this.commonDispatcher('', 'stand_speed_max')
      }
    },
    async getData() {
      clearInterval(this.interval_to_check_active);
      // if(this.wellId != '' || this.wellboreId != ''){
      await axios
        .post(configjs.HOST_URL.SERVER_URL + "public/swabsurgeinput/search", {
          wellId: this.wellId,
          wellBoreName: this.wellboreId,
          // wellId: "w1",
          // wellBoreName: "wb1",
        })
        .then(async (response) => {
          if (response.status == 200) {
            this.swab_surge_save_data = response.data;
            for (let eachObj of response.data) {
              if (eachObj.status.toUpperCase() === "A") {
                this.pausedDepth = eachObj.pausedDepth;
                this.tripdep = eachObj.depth;
                this.pipelength = eachObj.standPipeLength;
                this.pipelengthCng = eachObj.standPipeLength;
                this.modeSelected = eachObj.type.toUpperCase();
                this.modeSelectedCng = eachObj.type.toUpperCase();
                this.createdAt = eachObj.createdAt;
                // this.swabSurgeInputDataId = eachObj.swabSurgeInputDataId;
                this.swabName = eachObj.swabSurgeName;
                this.pausedTime = eachObj.pausedTime;

                // CHECKING FOR SWABSURGE TRIGGER DATA RUNNING RECORD
                await axios
                  .post(
                    configjs.HOST_URL.SERVER_URL + "public/swabsurge/search",
                    {
                      wellId: this.wellId,
                      wellBoreName: this.wellboreId,
                      // wellId: "w1",
                      // wellBoreName: "wb1",
                    }
                  )
                  .then((resp) => {
                    if (resp.status == 200) {
                      this.swab_surge_trigger_data = resp.data;
                      for (let each of resp.data) {
                        if (
                          (each.status == 0 &&
                            each.pausedStatus == null &&
                            eachObj.action?.toUpperCase() != "PAUSE") ||
                          (each.status == 0 &&
                            each.pausedStatus == 4 &&
                            eachObj.action?.toUpperCase() != "PAUSE")
                        ) {
                          // }
                          // if (each.status == 0) {
                          // IF STATUS IS RUNNING THEN ONLY ALLOWING TO PAUSE
                          this.isLoading = true;
                          this.isRun = false;
                          this.disable_run_btn = false;
                          this.show_pause_stop_btn = true;
                          // if (eachObj.action.toUpperCase() == "PAUSE") {
                          //   // IF ACTION IS PAUSE, ON SAVE CLICK, FOR CALLING A SERVICE TO RESTART AGAIN
                          //   this.rerun_after_pause = true;
                          // } else {
                          //   this.rerun_after_pause = false;
                          // }
                          break;
                        } else if (
                          eachObj.pausedDepth &&
                          eachObj.depth &&
                          each.status == 0 &&
                          each.pausedStatus == 1
                        ) {
                          // TO RESTART AFTER PAUSE OF ACTIVE ONE
                          let payload = {
                            // wellId: "w1",
                            // wellBoreName: "wb1",
                            wellId: this.wellId,
                            wellBoreName: this.wellboreId,
                            type: this.modeSelected,
                            standPipeLength: this.pipelength,
                            depth: this.tripdep,
                            pausedDepth: this.pausedDepth,
                            pausedTime: this.pausedTime,
                            action: "RUN",
                            status: "A",
                            createdBy: this.customer,
                            createdAt: this.createdAt,
                            swabSurgeName: this.swabName,
                            // swabSurgeInputDataId: this.swabSurgeInputDataId,
                          };
                          let url =
                            configjs.HOST_URL.SERVER_URL +
                            "public/swabsurgesolution/pause/restart";
                          axios
                            .post(url, payload)
                            .then((res) => {
                              if (res.status == 200) {
                                this.rerun_after_pause = false;
                                this.getData().then(() => {
                                  setTimeout(() => {
                                    if (this.swab_surge_trigger_data != "") {
                                      let record =
                                        this.swab_surge_trigger_data.find(
                                          (each) => each.status == 0
                                        );
                                      if (record) {
                                        this.ss_trigger_list_click(
                                          record.swabSurgeName,
                                          false,
                                          "running"
                                        );
                                      }
                                    }
                                  }, 10000);
                                });
                                // SHOWING STOP BTN
                                this.show_pause_stop_btn = false;
                              }
                            })
                            .catch((err) => {
                              this.$toast.clear();
                              this.$toast.error(err.message, {
                                duration: "2000",
                                position: "top",
                                pauseOnHover: true,
                              });
                            });
                          // this.rerun_after_pause = false;
                          // console.log(
                          //   "to restart status-active action-paused swab surge save list record"
                          // );
                        } else if (
                          ((each.status == 4 && each.pausedStatus == null) ||
                            (each.status == 4 && each.pausedStatus == 4)) &&
                          resp.data.length - 1 === resp.data.indexOf(each)
                        ) {
                          // To show stop button
                          this.isLoading = true;
                          this.isRun = false;
                          this.disable_run_btn = false;
                          this.show_pause_stop_btn = false;
                        } else if (
                          resp.data.length - 1 ===
                          resp.data.indexOf(each)
                        ) {
                          // this.isLoading = false;
                          // this.isRun = true;
                          // this.disable_run_btn = true;
                          this.isLoading = true;
                          this.isRun = false;
                          this.disable_run_btn = false;
                          this.show_pause_stop_btn = false;
                          // if (eachObj.action.toUpperCase() == "PAUSE") {
                          //   // IF ACTION IS PAUSE, ON SAVE CLICK, FOR CALLING A SERVICE TO RESTART AGAIN
                          //   this.rerun_after_pause = true;
                          // } else {
                          //   this.rerun_after_pause = false;
                          // }
                        }
                      }
                    } else if (resp.status == 204) {
                      this.swab_surge_trigger_data = [];
                      this.show_pause_stop_btn = false;
                      // this.isLoading = false;
                      // this.isRun = true;
                      // this.disable_run_btn = true;
                      this.isLoading = true;
                      this.isRun = false;
                      this.disable_run_btn = false;
                      // if (eachObj?.action?.toUpperCase() == "PAUSE") {
                      //   // IF ACTION IS PAUSE, ON SAVE CLICK, FOR CALLING A SERVICE TO RESTART AGAIN
                      //   this.rerun_after_pause = true;
                      // } else {
                      //   this.rerun_after_pause = false;
                      // }
                      this.$toast.clear();
                      this.$toast.error(
                        `No Data Available for Swab & Surge Trigger`,
                        {
                          duration: "2000",
                          position: "top",
                          pauseOnHover: true,
                        }
                      );
                    }
                  })
                  .catch((error) => {
                    this.swab_surge_trigger_data = [];
                    // this.isLoading = false;
                    // this.isRun = true;
                    // this.disable_run_btn = true;
                    this.isLoading = true;
                    this.isRun = false;
                    this.disable_run_btn = false;
                    this.$toast.clear();
                    this.$toast.error(error.message, {
                      duration: "2000",
                      position: "top",
                      pauseOnHover: true,
                    });
                  });
                this.interval_to_check_active = setInterval(
                  this.getData,
                  60000
                );
                break;
              } else if (
                response.data.length - 1 ===
                response.data.indexOf(eachObj)
              ) {
                this.disable_run_btn = false;
                this.show_pause_stop_btn = true;
                this.tripdep = "";
                this.pausedDepth = "";
                this.pipelength = "";
                this.pipelengthCng = "";
                this.modeSelected = "select";
                this.modeSelectedCng = "";
                this.createdAt = "";
                this.swabSurgeInputDataId = "";
                this.isLoading = false;
                this.isRun = true;
                this.rerun_after_pause = false;
                this.swabName = "";
                this.toUpdate_ss_save_list = true;
                clearInterval(this.interval_to_check_active);
              }
            }
          } else if (response.status == 204) {
            this.swab_surge_save_data = [];
            this.show_pause_stop_btn = true;
            this.toUpdate_ss_save_list = true;
            this.disable_run_btn = false;
            this.tripdep = "";
            this.pausedDepth = "";
            this.pipelength = "";
            this.pipelengthCng = "";
            this.modeSelected = "select";
            this.modeSelectedCng = "";
            this.createdAt = "";
            this.swabSurgeInputDataId = "";
            this.isLoading = false;
            this.isRun = true;
            this.rerun_after_pause = false;
            this.swabName = "";
            this.$toast.clear();
            this.$toast.error(`No Data Available for Swab & Surge Save`, {
              duration: "2000",
              position: "top",
              pauseOnHover: true,
            });
          }
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.error(`${err.message}`, {
            duration: "2000",
            position: "top",
            pauseOnHover: true,
          });
        });
      // }
      // else{
      //     this.$toast.error(`Please Select the Well and WellBore`,
      //     {
      //         duration: "2000", position: 'top', pauseOnHover: true
      //     });
      // }
    },
    async postData() {
      if (this.wellId != "" || this.wellboreId != "") {
        if (
          this.modeSelected != "" &&
          this.pipelength != "" &&
          this.action != ""
        ) {
          if (!this.rerun_after_pause) {
            let req_payload = {};
            if (!this.isLoading) {
              req_payload = {
                // wellId: "w1",
                // wellBoreName: "wb1",
                wellId: this.wellId,
                wellBoreName: this.wellboreId,
                type: this.modeSelected,
                standPipeLength: this.pipelength,
                action: this.action,
                createdBy: this.customer,
                status: "A",
                createdAt: new Date(),
              };
              if (this.statusSelected == "TIH") {
                req_payload["swabSurgeName"] = "TIH_" + this.swabName;
              } else if (this.statusSelected == "TOOH") {
                req_payload["swabSurgeName"] = "TOOH_" + this.swabName;
              }
            } else {
              if (this.action == "STOP") {
                req_payload = {
                  // wellId: "w1",
                  // wellBoreName: "wb1",
                  wellId: this.wellId,
                  wellBoreName: this.wellboreId,
                  type: this.modeSelected,
                  standPipeLength: this.pipelength,
                  depth: this.tripdep,
                  pausedDepth: this.pausedDepth,
                  pausedTime: this.pausedTime,
                  action: "END",
                  status: "I",
                  createdBy: this.customer,
                  createdAt: this.createdAt,
                  swabSurgeName: this.swabName,
                  // swabSurgeInputDataId: this.swabSurgeInputDataId,
                };

                axios
                  .post(
                    configjs.HOST_URL.SERVER_URL +
                      "public/swabsurgeinput/stopordelete",
                    req_payload
                  )
                  .then(async (res) => {
                    if (res.status == 200) {
                      await this.getData();
                      await this.checking_well_run(true);
                    }
                  })
                  .catch((err) => {
                    this.$toast.clear();
                    this.$toast.error(err.message, {
                      duration: "2000",
                      position: "top",
                      pauseOnHover: true,
                    });
                  });

                // TO STOP THE EXECUTING NEXT AXIOS CALL
                return;
              } else if (this.action == "PAUSE") {
                req_payload = {
                  // wellId: "w1",
                  // wellBoreName: "wb1",
                  wellId: this.wellId,
                  wellBoreName: this.wellboreId,
                  type: this.modeSelected,
                  standPipeLength: this.pipelength,
                  depth: this.tripdep,
                  pausedDepth: null,
                  pausedTime: null,
                  action: this.action,
                  status: "A",
                  createdBy: this.customer,
                  createdAt: this.createdAt,
                  swabSurgeName: this.swabName,
                  // swabSurgeInputDataId: this.swabSurgeInputDataId,
                };
              } else {
                req_payload = {
                  // wellId: "w1",
                  // wellBoreName: "wb1",
                  wellId: this.wellId,
                  wellBoreName: this.wellboreId,
                  type: this.modeSelected,
                  standPipeLength: this.pipelength,
                  depth: this.tripdep,
                  pausedDepth: this.pausedDepth,
                  pausedTime: this.pausedTime,
                  action: this.action,
                  status: "A",
                  createdBy: this.customer,
                  createdAt: this.createdAt,
                  swabSurgeName: this.swabName,
                  // swabSurgeInputDataId: this.swabSurgeInputDataId,
                };
              }
            }

            axios
              .post(
                configjs.HOST_URL.SERVER_URL +
                  "public/swabsurgeinput/saveorupdate",
                req_payload
              )
              .then(() => {
                // this.toUpdate_ss_save_list = true;
                this.getData();
              })
              .catch((err) => {
                if (!this.isLoading) {
                  this.swabName = "";
                  this.pipelength = "";
                  this.statusSelected = "select";
                  this.modeSelected = "select";
                }
                this.$toast.clear();
                this.$toast.error(err.message, {
                  duration: "2000",
                  position: "top",
                  pauseOnHover: true,
                });
              });
          } else {
            // TO RESTART AFTER PAUSE OF ACTIVE ONE
            // let payload = {
            //   // wellId: "w1",
            //   // wellBoreName: "wb1",
            //   wellId: this.wellId,
            //   wellBoreName: this.wellboreId,
            //   type: this.modeSelected,
            //   standPipeLength: this.pipelength,
            //   depth: this.tripdep,
            //   pausedDepth: this.pausedDepth,
            //   pausedTime: this.pausedTime,
            //   action: "RUN",
            //   status: "A",
            //   createdBy: this.customer,
            //   createdAt: this.createdAt,
            //   swabSurgeName: this.swabName,
            //   swabSurgeInputDataId: this.swabSurgeInputDataId,
            // };
            // let url =
            //   configjs.HOST_URL.DATASERVICES_URL +
            //   "swabsurgesolution/pause/restart";
            // axios
            //   .post(url, payload)
            //   .then((res) => {
            //     if (res.status == 200) {
            //       this.rerun_after_pause = false;
            //       this.getData();
            //     }
            //   })
            //   .catch((err) => {
            //     this.$toast.clear();
            //     this.$toast.error(err, {
            //       duration: "2000",
            //       position: "top",
            //       pauseOnHover: true,
            //     });
            //   });
            // // this.rerun_after_pause = false;
            // console.log(
            //   "to restart status-active action-paused swab surge save list record"
            // );
          }
        } else {
          this.$toast.clear();
          this.$toast.error(`Fields Should not be blank`, {
            duration: "2000",
            position: "top",
            pauseOnHover: true,
          });
        }
      } else {
        this.$toast.clear();
        this.$toast.error(`Please Select the Well and WellBore`, {
          duration: "2000",
          position: "top",
          pauseOnHover: true,
        });
      }
    },
    change_toUpdate_ss_save_list() {
      this.toUpdate_ss_save_list = false;
    },
    async ss_trigger_list_click(swabsurgeName, from_zoom, status) {
      await this.takeOffSocket();
      this.ss_trigger_clicked_swabsurgeName = swabsurgeName;
      let req_body = {};
      if (from_zoom) {
        req_body = {
          swabSurgeDataId:
            this.wellId + "_" + this.wellboreId + "_" + swabsurgeName,
          // swabSurgeDataId: "ABS",
          swabSurgeSearch: false,
          // toDbtm: 5,
          toDbtm: this.to_Depth,
          toDbtmOperator: "LTE",
          fromDbtmOperator: "GTE",
          // dbtm: 1,
          dbtm: this.from_Depth,
        };
      } else {
        req_body = {
          swabSurgeDataId:
            this.wellId + "_" + this.wellboreId + "_" + swabsurgeName,
          // swabSurgeDataId: "ABS",
          swabSurgeSearch: false,
        };
      }

      if (status && status == "running") {
        let payload = {
          swab_surge_data_id:
            this.wellId + "_" + this.wellboreId + "_" + swabsurgeName,
        };
        axios
          .post(
            configjs.HOST_URL.SERVER_URL + "public/swab-and-surge-nth",
            payload
          )
          .then(async (res) => {
            if (res.status == 200) {
              this.graph3Data = res.data;
              await this.getRealtimeData();
              await this.set3DGraph3Data();
            } else {
              await this.getRealtimeData();
            }
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        axios
          .post(
            configjs.HOST_URL.SERVER_URL + "public/swabsurgedata/search",
            req_body
          )
          .then((res) => {
            if (res.status == 200) {
              this.graph3Data = res.data;
              this.set3DGraph3Data();
            } else if (res.status == 204) {
              this.$toast.clear();
              this.$toast.error(`No Data Available`, {
                duration: "2000",
                position: "top",
                pauseOnHover: true,
              });
            }
          });
      }
    },
    async realTime_show_right_side_bar(object) {
      let obj = object.data;
      this.$store.dispatch("rect/clickedGraph", {
        // value: "tvdTrajectoryTrend31",
        value: "tvdTrajectoryTrend3" + this.widgetId,
      });
      let arrObj = [];
      Object.keys(obj.swabSurgeData).map((key) => {
        if (key == "bitSize") {
          arrObj.push({ respose_key: key, v_model_key: "bit_size_live" });
        } else if (key == "casingid") {
          arrObj.push({ respose_key: key, v_model_key: "cassing_id" });
        } else if (key == "casinglength") {
          arrObj.push({ respose_key: key, v_model_key: "cassing_length" });
        } else if (key == "consistencyIndex") {
          arrObj.push({
            respose_key: key,
            v_model_key: "Consistency_index_Live",
          });
        } else if (key == "dc1Casinglength") {
          arrObj.push({
            respose_key: key,
            v_model_key: "dc1_casing_length_live",
          });
        } else if (key == "dc1OpenHolelength") {
          arrObj.push({
            respose_key: key,
            v_model_key: "dc1_open_hole_length_live",
          });
        } else if (key == "dc1id") {
          arrObj.push({ respose_key: key, v_model_key: "dc1_id_Live" });
        } else if (key == "dc1length") {
          arrObj.push({ respose_key: key, v_model_key: "dc1_length_Live" });
        } else if (key == "dc1od") {
          arrObj.push({ respose_key: key, v_model_key: "dc1_od_Live" });
        } else if (key == "dc2Casinglength") {
          arrObj.push({
            respose_key: key,
            v_model_key: "dc2_casing_length_live",
          });
        } else if (key == "dc2OpenHolelength") {
          arrObj.push({
            respose_key: key,
            v_model_key: "dc2_open_hole_length_live",
          });
        } else if (key == "dc2id") {
          arrObj.push({ respose_key: key, v_model_key: "dc2_id_Live" });
        } else if (key == "dc2length") {
          arrObj.push({ respose_key: key, v_model_key: "dc2_length_Live" });
        } else if (key == "dc2od") {
          arrObj.push({ respose_key: key, v_model_key: "dc2_od_Live" });
        } else if (key == "dmea") {
          arrObj.push({ respose_key: key, v_model_key: "dmea_live" });
        } else if (key == "dpCasinglength") {
          arrObj.push({
            respose_key: key,
            v_model_key: "dp_casing_length_live",
          });
        } else if (key == "dpOpenHolelength") {
          arrObj.push({
            respose_key: key,
            v_model_key: "dp_open_hole_length_live",
          });
        } else if (key == "dpid") {
          arrObj.push({ respose_key: key, v_model_key: "dp_id_Live" });
        } else if (key == "dplength") {
          arrObj.push({ respose_key: key, v_model_key: "dp_length_Live" });
        } else if (key == "dpod") {
          arrObj.push({ respose_key: key, v_model_key: "dp_od_Live" });
        } else if (key == "flowIndex") {
          arrObj.push({ respose_key: key, v_model_key: "flow_index_Live" });
        } else if (key == "hwdpCasinglength") {
          arrObj.push({
            respose_key: key,
            v_model_key: "hwdp_casing_length_live",
          });
        } else if (key == "hwdpOpenHolelength") {
          arrObj.push({
            respose_key: key,
            v_model_key: "hwdp_open_hole_length_live",
          });
        } else if (key == "hwdpid") {
          arrObj.push({ respose_key: key, v_model_key: "hwdp_id_Live" });
        } else if (key == "hwdplength") {
          arrObj.push({ respose_key: key, v_model_key: "hwdp_length_Live" });
        } else if (key == "hwdpod") {
          arrObj.push({ respose_key: key, v_model_key: "hwdp_od_Live" });
        } else if (key == "muddensity") {
          arrObj.push({ respose_key: key, v_model_key: "mud_wght_Live" });
        } else if (key == "plasticViscosity") {
          arrObj.push({
            respose_key: key,
            v_model_key: "plastic_Viscosity_Live",
          });
        } else if (key == "surgePressure") {
          arrObj.push({ respose_key: key, v_model_key: "surge_pressure_live" });
        } else if (key == "surgePressureinppg") {
          arrObj.push({
            respose_key: key,
            v_model_key: "surge_pressure_in_ppg_live",
          });
        } else if (key == "swabPressure") {
          arrObj.push({ respose_key: key, v_model_key: "swab_pressure_live" });
        } else if (key == "swabPressureinppg") {
          arrObj.push({
            respose_key: key,
            v_model_key: "swab_pressure_in_ppg_live",
          });
        } else if (key == "tripMode") {
          arrObj.push({
            respose_key: key,
            v_model_key: "selected_tripmode_Live",
          });
        } else if (key == "tripSpeed") {
          arrObj.push({ respose_key: key, v_model_key: "trip_speed_live" });
        } else if (key == "tripStatus") {
          arrObj.push({
            respose_key: key,
            v_model_key: "selected_tripstatus_Live",
          });
        } else if (key == "r300") {
          arrObj.push({
            respose_key: key,
            v_model_key: "r300_Live",
          });
        } else if (key == "r600") {
          arrObj.push({
            respose_key: key,
            v_model_key: "r600_Live",
          });
        } else if (key == "yieldPoint") {
          arrObj.push({
            respose_key: key,
            v_model_key: "yield_point_Live",
          });
        } else if (key == "tvd") {
          arrObj.push({
            respose_key: key,
            v_model_key: "trip_depth_tvd_Live",
          });
        }
      });

      for (let { respose_key, v_model_key } of arrObj) {
        this.$store.dispatch("rect/swabSurgeRealTimedata", {
          displayId: this.displayId,
          widgetId: this.widgetId,
          value: obj.swabSurgeData[respose_key],
          field: v_model_key,
        });
      }
      this.$store.dispatch("rect/swabSurgeRealTimedata", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: obj.dbtm,
        field: "dbtm_live",
      });
      this.$emit("to_openProperty", this.rect_index, this.rect_data);
      // await this.check_propty_bar_hide_left_bar();
    },
    realTime_psi_ppg_click() {
      if (this.graph3Data) {
        this.set3DGraph3Data();
      }
    },
    plot_graph_on_zoom() {
      if (
        this.ss_trigger_clicked_swabsurgeName &&
        this.from_Depth !== null &&
        this.from_Depth !== "" &&
        this.to_Depth !== null &&
        this.to_Depth !== ""
      ) {
        this.ss_trigger_list_click(this.ss_trigger_clicked_swabsurgeName, true);
        this.show_popup_on_zoom = false;
      } else {
        this.$toast.clear();
        this.$toast.error(`Please Enter Values Above`, {
          duration: "2000",
          position: "top",
          pauseOnHover: true,
        });
      }
    },
    hide_popup_on_zoom() {
      this.show_popup_on_zoom = false;
    },
    async print() {
      try {
        const stream = await navigator.mediaDevices.getDisplayMedia({
          preferCurrentTab: true,
        });
        const videoTrack = stream.getVideoTracks()[0];
        const imageCapture = new ImageCapture(videoTrack);
        const bitmap = await imageCapture.grabFrame();
        videoTrack.stop();

        const canvas = document.getElementById("canvas_img" + this.widgetId);
        const ctx = canvas.getContext("2d");

        canvas.width = bitmap.width;
        canvas.height = bitmap.height;
        ctx.drawImage(bitmap, 0, 0, canvas.width, canvas.height);

        const image = canvas.toDataURL();
        this.capturedImage = image;
        this.show_print_popup = true;
      } catch (err) {
        console.log(err);
        this.show_print_popup = false;
      }
    },
    tripmode(event, field) {
      this.dispatch_into_rect_rects(event.target.value, field);
    },
    dispatch_into_rect_rects(val, field) {
      this.$store.dispatch("rect/swabSurgeRealTimedata", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: val,
        field: field,
      });
    },
    async show_real_time_tool_tip(e, dataOnHover) {
      let data = dataOnHover.data;
      await this.initial_setting_positions(e, 132, 90);
      // this.mouse_x = e.clientX;
      // this.mouse_y = e.clientY;
      this.real_time_hole_depth = data.swabSurgeData.dmea || 0;
      this.real_time_bit_depth = data.dbtm || 0;
      this.real_time_trip_speed = data.swabSurgeData.tripSpeed || 0;
      this.real_time_trip_status = data.swabSurgeData.tripStatus || 0;
      this.real_time_swab_pressure = data.swabSurgeData.swabPressure || 0;
      this.real_time_surge_pressure = data.swabSurgeData.surgePressure || 0;
      this.real_time_swab_pressure_in_ppg =
        data.swabSurgeData.swabPressureinppg || 0;
      this.real_time_surge_pressure_in_ppg =
        data.swabSurgeData.surgePressureinppg || 0;
      this.real_time_tool_tip = true;
      await this.final_setting_positions(e, "real_time_graph");
    },
    hide_real_time_tool_tip() {
      this.real_time_tool_tip = false;
      this.real_time_hole_depth = null;
      this.real_time_bit_depth = null;
      this.real_time_trip_speed = null;
      this.real_time_trip_status = null;
      this.real_time_swab_pressure = null;
      this.real_time_surge_pressure = null;
      this.real_time_swab_pressure_in_ppg = null;
      this.real_time_surge_pressure_in_ppg = null;
    },
    async show_static_tool_tip(e, data) {
      await this.initial_setting_positions(e, 150, 108);
      // this.mouse_x = e.clientX;
      // this.mouse_y = e.clientY;
      // this.static_graph_1_hole_depth = data.swabSurgeList.depth
      this.static_graph_1_speed = data.x || 0;
      this.static_graph_1_bit_depth = data.data.dbtm || 0;
      this.static_graph_1_trip_depth = data.data.depth || 0;
      this.static_graph_1_trip_speed_min = data.data.minPipeVelocity || 0;
      this.static_graph_1_trip_speed_max = data.data.maxPipeVelocity || 0;
      this.static_graph_1_swabAndSurgePressure =
        data.data.swabAndSurgePressure || 0;
      this.static_graph_1_swabAndSurgePressureinppg =
        data.data.swabAndSurgePressureinppg || 0;
      this.static_graph_1_tool_tip = true;
      await this.final_setting_positions(e, "static_graph_1");
    },
    hide_static_tool_tip() {
      this.static_graph_1_tool_tip = false;
      this.static_graph_1_speed = null;
      this.static_graph_1_bit_depth = null;
      (this.static_graph_1_trip_depth = null),
        (this.static_graph_1_trip_speed_min = null),
        (this.static_graph_1_trip_speed_max = null),
        (this.static_graph_1_swabAndSurgePressure = null);
      this.static_graph_1_swabAndSurgePressureinppg = null;
    },
    async show_static_tool_tip_graph_2(e, data) {
      await this.initial_setting_positions(e, 134, 90);
      // this.mouse_x = e.clientX;
      // this.mouse_y = e.clientY;
      // this.static_graph_2_hole_depth = data.swabSurgeList.depth
      this.static_graph_2_trip_depth = data.y || 0;
      this.static_graph_2_trip_speed = data.swab.tripSpeed || 0;
      this.static_graph_2_bit_depth = data.data.dbtm || 0;
      this.static_graph_2_swabAndSurgePressure =
        data.swab.swabAndSurgePressure || 0;
      this.static_graph_2_swabAndSurgePressureinppg =
        data.swab.swabAndSurgePressureinppg || 0;
      this.static_graph_2_tool_tip = true;
      await this.final_setting_positions(e, "static_graph_2");
    },
    hide_static_tool_tip_graph_2() {
      this.static_graph_2_tool_tip = false;
      this.static_graph_2_trip_depth = null;
      this.static_graph_2_trip_speed = null;
      this.static_graph_2_bit_depth = null;
      this.static_graph_2_swabAndSurgePressure = null;
      this.static_graph_2_swabAndSurgePressureinppg = null;
    },
    async checking_well_run(afterStop) {
      try {
        let res = await axios.post(
          configjs.HOST_URL.SERVER_URL + "public/sourcelog/search",
          {
            customerName: this.$store.state.data?.customer || "",
            wellId: this.wellId,
            wellBoreName: this.wellboreId,
          }
        );
        if (res.status == 200) {
          if (res.data != "") {
            let time_log = res.data.find(
              (each) => each.log?.toLowerCase() == "time"
            );
            if (time_log) {
              time_log.wellStatus == 4 || time_log.wellStatus == 104
                ? (this.well_running = true)
                : (this.well_running = false);
            } else {
              this.well_running = false;
            }
          }
        } else {
          this.well_running = false;
        }
        if (!this.well_running) {
          if (afterStop) {
            let have_active_recrd = this.swab_surge_save_data.some(
              (each) => each.status.toUpperCase() === "A"
            );
            if (have_active_recrd) {
              this.well_running = true;
              return;
            }
          } else {
            if (this.swab_surge_trigger_data != "") {
              let have_running_recrd = this.swab_surge_trigger_data.some(
                (each) => each.status == 0
              );
              if (have_running_recrd) {
                this.well_running = true;
                return;
              }
            }
          }
          // this.$toast.clear();
          this.$toast.error("Well is not running", {
            duration: "2000",
            position: "top",
            pauseOnHover: true,
          });
        }
      } catch (err) {
        this.well_running = false;
        console.error(err);
      }
    },
    initial_setting_positions(e, width, height) {
      if (e.clientX > window.innerWidth * 0.7) {
        this.mouse_x = e.clientX - width;
      } else {
        this.mouse_x = e.clientX;
      }
      if (e.clientY > window.innerHeight * 0.7) {
        this.mouse_y = e.clientY - height - 25;
      } else {
        this.mouse_y = e.clientY;
      }
    },
    final_setting_positions(e, refName) {
      setTimeout(() => {
        let poses = this.$refs[refName].getBoundingClientRect();
        if (e.clientX > window.innerWidth * 0.7) {
          this.mouse_x = e.clientX - poses.width;
        } else {
          this.mouse_x = e.clientX;
        }
        if (e.clientY > window.innerHeight * 0.7) {
          this.mouse_y = e.clientY - poses.height - 25;
        } else {
          this.mouse_y = e.clientY;
        }
      }, 0);
    },
    // check_propty_bar_hide_left_bar(){
    //   if(this.$store.state?.disp?.toggle_right_Sidebar){
    //     if (this.sideBarForProp == "TD") {
    //       this.sideBarForProp = "";
    //       this.toggleSidebar = 0;
    //     }
    //   }
    //   try {
    //     this.buildSVG1(this.widgetId);
    //     this.buildSVG2(this.widgetId);
    //     this.buildSVG3(this.widgetId);
    //   } catch (err) {
    //     console.error(err);
    //   }
    // },
  },

  async mounted() {
    if (
      !this.$store.state.rect.rects?.some(
        (each) =>
          each.displayId == this.displayId &&
          each.widgetId == this.widgetId + "swabsurge_gauge_1"
      )
    ) {
      await this.$store.dispatch("rect/addNewRectForVerticalBar", this.rect);
    }
    if (
      !this.$store.state.rect.rects?.some(
        (each) =>
          each.displayId == this.displayId &&
          each.widgetId == this.widgetId + "swabsurge_gauge_2"
      )
    ) {
      await this.$store.dispatch("rect/addNewRectForVerticalBar", this.rect2);
    }
    let apollodart = this.$serviceHelpers.getDetails("apollodart");
    this.customer = apollodart.user?.user_name;
    let displayId = this.displayId;
    let displayDtls = this.$store.state.disp.displays[displayId];

    if (displayDtls) {
      this.wellId = displayDtls.wellId;
      this.wellboreId = displayDtls.wellboreId;
      this.logId = displayDtls.logId;
      this.status = displayDtls.status;
      this.logType = displayDtls.logType;
      this.logTypeId = displayDtls.logTypeId;
      this.table = displayDtls.table;
      this.topic = displayDtls.swab_surge_topicname;
      this.isWellMetrics = displayDtls.unit_conversion;
      // this.getRealtimeData("mounted");
    }
    this.$store.subscribe(async (mutation, state) => {
      if (
        mutation.type == "disp/setDisplay" &&
        this.displayId == mutation.payload.display
      ) {
        // console.log("mutation.payload ", mutation.payload);
        this.isWellMetrics = mutation.payload.unit_conversion;
        this.takeOffSocket();
        this.selectedSavedRun = {};
        this.selectedWellInfo = {};
        this.wellId = mutation.payload.wellId;
        this.wellboreId = mutation.payload.wellboreId;
        this.logId = mutation.payload.logId;
        this.status = mutation.payload.status;
        this.logType = mutation.payload.logType;
        this.logTypeId = mutation.payload.logTypeId;
        this.topic = mutation.payload.swab_surge_topicname;
        this.isZoomFeature = false;
        this.mouse_x = 0;
        this.mouse_y = 0;
        this.isPlanTooltip = false;
        this.planTooltipValues = "";
        this.realtimeTimeOut = "";
        this.isSaved = false;
        this.whichShow = "drilling";
        this.isRealtime = false;
        this.sideBarForProp = "";
        this.width = this.widgetWidth;
        this.height = this.widgetHeight;
        this.graphData = [];
        this.columns = [];
        this.rtColumn = [];
        this.plannedColumn = [];
        this.colors = [];
        this.minX = 0;
        this.maxX = 100;
        this.minY = 0;
        this.maxY = this.trip_Depth;
        this.isZoomed = false;
        this.extent = [];
        this.extent_static_graph_2 = [];
        this.extent_real_time_graph = [];
        this.soColor = "";
        this.puColor = "";
        this.robColor = "";
        this.slackoff = "";
        this.pickup = "";
        this.rotateoffbottom = "";
        this.slackofftripin = "";
        this.pickuptripout = "";
        this.toggleSidebar = "-16";
        this.sideBarFor = "";
        this.tdopen = false;
        this.planId = "";
        this.bhaId = "";
        this.sourceid = "";
        this.start_time = this.bhaStartTime;
        this.end_time = this.bhaEndTime;
        this.bhaCount = 0;
        this.selectedBhaSourceId = "";
        this.selected_start_time = "";
        this.selected_end_time = "";
        this.wellFormation = [];
        this.geometry = [];
        this.buildSVG1(this.widgetId);
        this.buildSVG2(this.widgetId);
        this.buildSVG3(this.widgetId);
        this.table = mutation.payload.table;
        this.zoom_checked = this.isZoomFeature;
        // this.takeOffSocket();
        // this.getRealtimeData("mutation");
      }
    });
    this.buildSVG1(this.widgetId);
    this.buildSVG2(this.widgetId);
    this.buildSVG3(this.widgetId);
    let insert_ranges = [
      { field: "xAutoScale", val: false },
      { field: "yAutoScale", val: false },
      { field: "xRangeStart", val: 0 },
      { field: "yRangeStart", val: 0 },
      { field: "xRangeEnd", val: 10 },
      { field: "yRangeEnd", val: 11 },
      { field: "xAutoScalePpg", val: true },
      { field: "yAutoScalePpg", val: false },
      { field: "xRangeStartPpg", val: 8 },
      { field: "yRangeStartPpg", val: 0 },
      { field: "xRangeEndPpg", val: 15 },
      { field: "yRangeEndPpg", val: 11 },
    ];
    insert_ranges.map((each) => {
      if (!Object.keys(this.rect_data).includes(each.field)) {
        this.dispatch_into_rect_rects(each.val, each.field);
      }
    });
  },
  computed: {
    // async rightToggleCheck(){
    //   try{
    //     if(this.$store.state?.disp?.toggle_right_Sidebar === false){
    //       await this.buildSVG1(this.widgetId);
    //       await this.buildSVG2(this.widgetId);
    //       await this.buildSVG3(this.widgetId);
    //     }
    //   }
    //   catch(err){console.error(err)}
    //   return this.$store.state?.disp?.toggle_right_Sidebar || false
    // },
    speed_min(){
      let foundRecord = this.$store.state.rect.rects.find((rect)=> rect.widgetId == this.widgetId && rect.displayId == this.displayId)
      if(foundRecord && foundRecord.hasOwnProperty('speed_min')){
        return foundRecord.speed_min;
      }
      else{
        return '';
      }
    },
    speed_max(){
      let foundRecord = this.$store.state.rect.rects.find((rect)=> rect.widgetId == this.widgetId && rect.displayId == this.displayId)
      if(foundRecord && foundRecord.hasOwnProperty('speed_max')){
        return foundRecord.speed_max;
      }
      else{
        return '';
      }
    },
    pipe_length(){
      let foundRecord = this.$store.state.rect.rects.find((rect)=> rect.widgetId == this.widgetId && rect.displayId == this.displayId)
      if(foundRecord && foundRecord.hasOwnProperty('pipe_length')){
        return foundRecord.pipe_length;
      }
      else{
        return '';
      }
    },
    stand_speed_min(){
      let foundRecord = this.$store.state.rect.rects.find((rect)=> rect.widgetId == this.widgetId && rect.displayId == this.displayId)
      if(foundRecord && foundRecord.hasOwnProperty('stand_speed_min')){
        return foundRecord.stand_speed_min;
      }
      else{
        return '';
      }
    },
    stand_speed_max(){
      let foundRecord = this.$store.state.rect.rects.find((rect)=> rect.widgetId == this.widgetId && rect.displayId == this.displayId)
      if(foundRecord && foundRecord.hasOwnProperty('stand_speed_max')){
        return foundRecord.stand_speed_max;
      }
      else{
        return '';
      }
    },
    darkDark() {
      if (this.$store.state.rect.darkmode !== "white") {
        this.$store.dispatch("rect/lineGraphYAxis", {
          displayId: this.displayId,
          widgetId: this.widgetId,
          value: "white",
        });
        this.$store.dispatch("rect/lineGraphXAxis", {
          displayId: this.displayId,
          widgetId: this.widgetId,
          value: "white",
        });
      } else {
        this.$store.dispatch("rect/lineGraphYAxis", {
          displayId: this.displayId,
          widgetId: this.widgetId,
          value: "black",
        });
        this.$store.dispatch("rect/lineGraphXAxis", {
          displayId: this.displayId,
          widgetId: this.widgetId,
          value: "black",
        });
      }
      return this.$store.state.rect.darkmode;
    },
  },
  watch: {
    actualColor: async function (newVal, oldVal) {
      this.buildSVG1(this.widgetId);
      this.buildSVG2(this.widgetId);
      this.buildSVG3(this.widgetId);
    },
    bhaStartTime: async function (newVal, oldVal) {
      this.start_time = newVal;
    },
    bhaEndTime: async function (newVal, oldVal) {
      this.end_time = newVal;
    },
    gridColor: async function (newVal, oldVal) {
      try {
        this.buildSVG1(this.widgetId);
        this.buildSVG2(this.widgetId);
        this.buildSVG3(this.widgetId);
      } catch (error) {}
    },
    gridBackground: async function (newVal, oldVal) {
      try {
        this.buildSVG1(this.widgetId);
        this.buildSVG2(this.widgetId);
        this.buildSVG3(this.widgetId);
      } catch (error) {}
    },
    xAxisLabel: async function (newVal, oldVal) {
      try {
        this.buildSVG1(this.widgetId);
        this.buildSVG2(this.widgetId);
        this.buildSVG3(this.widgetId);
      } catch (error) {}
    },
    yAxisLabel: async function (newVal, oldVal) {
      try {
        this.buildSVG1(this.widgetId);
        this.buildSVG2(this.widgetId);
        this.buildSVG3(this.widgetId);
      } catch (error) {}
    },
    xAxisLabelSize: async function (newVal, oldVal) {
      try {
        this.buildSVG1(this.widgetId);
        this.buildSVG2(this.widgetId);
        this.buildSVG3(this.widgetId);
      } catch (error) {
        //
      }
    },
    yAxisLabelSize: async function (newVal, oldVal) {
      try {
        this.buildSVG1(this.widgetId);
        this.buildSVG2(this.widgetId);
        this.buildSVG3(this.widgetId);
      } catch (error) {}
    },
    widgetWidth: async function (newVal, oldVal) {
      try {
        this.width = newVal;
        this.buildSVG1(this.widgetId);
        this.buildSVG2(this.widgetId);
        this.buildSVG3(this.widgetId);
      } catch (error) {
        //
      }
    },
    widgetHeight: async function (newVal, oldVal) {
      try {
        this.height = newVal;
        this.buildSVG1(this.widgetId);
        this.buildSVG2(this.widgetId);
        this.buildSVG3(this.widgetId);
      } catch (error) {
        //
      }
    },
    isZoomFeature(newValue) {
      this.zoom_checked = newValue;
    },
    mud_wght(newValue) {
      this.mud_density = newValue;
    },
    selected_tripmode(newValue) {
      this.selected_trip_mode = newValue;
    },
    selected_tripstatus(newValue) {
      this.selected_trip_statues = newValue;
      if (newValue.toUpperCase() == "TIH") {
        this.selected_trip_status = 5;
      }
      if (newValue.toUpperCase() == "TOOH") {
        this.selected_trip_status = 6;
      }
    },
    trip_Depth(newValue) {
      this.trip_Depth_maxy = newValue;
    },
    async wellboreId() {
      if (this.wellId && this.wellboreId) {
        this.unitRefreshKey+=3;
        this.swab_surge_trigger_data = [];
        this.graph3Data = [];
        this.megaData3 = [];
        await this.buildSVG3(this.widgetId);
        await this.getData();
        if (this.swab_surge_trigger_data != "") {
          let record = this.swab_surge_trigger_data.find(
            (each) => each.status == 0
          );
          if (record) {
            await this.ss_trigger_list_click(
              record.swabSurgeName,
              false,
              "running"
            );
          }
        }
        await this.checking_well_run();
        this.$store.dispatch("disp/setSelectedDisplay", this.displayId);
      }
    },
    async xAutoScale(val) {
      await this.buildSVG3(this.widgetId);
    },
    async yAutoScale(val) {
      await this.buildSVG3(this.widgetId);
    },
    async xRangeStart(val) {
      await this.buildSVG3(this.widgetId);
    },
    async xRangeEnd(val) {
      await this.buildSVG3(this.widgetId);
    },
    async yRangeStart(val) {
      await this.buildSVG3(this.widgetId);
    },
    async yRangeEnd(val) {
      await this.buildSVG3(this.widgetId);
    },
    async xAutoScalePpg(val) {
      await this.buildSVG3(this.widgetId);
    },
    async yAutoScalePpg(val) {
      await this.buildSVG3(this.widgetId);
    },
    async xRangeStartPpg(val) {
      await this.buildSVG3(this.widgetId);
    },
    async xRangeEndPpg(val) {
      await this.buildSVG3(this.widgetId);
    },
    async yRangeStartPpg(val) {
      await this.buildSVG3(this.widgetId);
    },
    async yRangeEndPpg(val) {
      await this.buildSVG3(this.widgetId);
    },
  },
};
</script>
<style>
/* Fonts Size Css */
.text-xxxs {
  font-size: 10px;
}
.text-xxs {
  font-size: 11px;
  text-transform: uppercase;
}
.text-sx {
  font-size: 9px;
}
.tick text,
text.label {
  fill: currentColor;
}
.swaborsurge .toggle_cntr {
  margin: 3px auto;
}
.swaborsurge .checkbox-label {
  width: 30px;
  height: 14px;
}
.swaborsurge .ball {
  width: 12px;
  height: 12px;
  left: 2px;
  top: 1px;
}
.swaborsurge .td_checkbox_zoom {
  background-color: var(--toggleSliderBg);
}
.swaborsurge .checkbox:checked + .td_checkbox_zoom {
  background-color: var(--activeTextColor);
}
.swaborsurge .checkbox:checked + .td_checkbox_zoom .ball {
  transform: translateX(14px);
  /* background: #5aba81; */
}
.swaborsurge .checkbox-label .ball {
  background-color: var(--sidebarbg);
}
#tvdTrajectoryTrend31 .legends {
  display: none;
}
.btns {
  padding: 4px 8px !important;
}
.text-sxx {
  font-size: 7px;
}
.curve_pop_opened.top {
  top: 0;
}
.run,
.greens {
  background: green;
}
.stop {
  background: red;
}
.blues {
  background-color: #0a58ca;
}
.runInfo {
  z-index: 999;
  position: absolute;
  top: 0%;
  left: 0%;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
}
.popup {
  position: absolute;
  top: 40%;
  left: 50%;
  /* background: rgba(0, 0, 0); */
  /* background: var(--colorBoxPopUpBg); */
  background: var(--sidebarbg);
  color: var(--textColor);
  transform: translate(-50%, -40%);
}
.popup input,
.popup select {
  font-weight: 600;
  background: var(--sidebarListBgColor) !important;
}
.selc {
  width: 99px !important;
  height: 22px !important;
  padding: 0 !important;
  border-width: 1px !important;
  border-top: 1px solid !important;
  /* padding: 0px 13px !important; */
  padding: 0px 0.5rem !important;
  margin-left: 10px;
  display: flex;
  align-items: center;
  font-size: 10px;
}
.selcs {
  width: 76px !important;
  height: 22px !important;
  padding: 0 !important;
  border-width: 1px !important;
  border-top: 1px solid !important;
  /* padding: 0px 10px !important; */
  padding: 0px 0.5rem !important;
  display: flex;
  align-items: center;
  font-size: 10px;
}
.svbtn {
  padding: 3px 8px !important;
  /* border: 1px solid #03e9f4 !important; */
  outline: none !important;
  /* color: #03e9f4 !important; */
  max-width: 125px;
  color: var(--activeTextColor);
  border-color: currentColor;
}
.info {
  padding: 3px 8px !important;
  border: 1px solid #fda900 !important;
  outline: none !important;
  color: #fda900 !important;
  max-width: 125px;
}
.cnclbtn {
  padding: 3px 8px !important;
  border: 1px solid #f40303 !important;
  outline: none !important;
  color: #f40303 !important;
  max-width: 135px;
  margin-right: 10px;
}
.swaborsurge .Child_Of_sendMail_modal {
  width: 80%;
  height: 90%;
  margin: auto;
  border-radius: 2px;
  /* background: var(--colorBoxPopUpBg); */
  background: var(--sidebarListBgColor);
  color: var(--textColor);
}
.swaborsurge .sendMail_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: rgba(28, 29, 31, 0.7000000000000001);
  display: flex;
  z-index: 999999;
  align-items: center;
}
.wid {
  width: 76px !important;
}
.svbtn[disabled] {
  opacity: 0.4;
  pointer-events: none;
}
/* Chrome, Safari, Edge, Opera */
.zoom_popup input[type="number"]::-webkit-outer-spin-button,
.zoom_popup input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
.zoom_popup input[type="number"] {
  -moz-appearance: textfield;
}
.run[disabled],
.stop[disabled] {
  opacity: 0.5;
}
.swaborsurge .print_popup {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgb(28 29 31 / 70%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}
.sub_print_popup {
  background: var(--colorBoxPopUpBg);
  color: var(--textColor);
  width: 60%;
  height: 80%;
}
.real_time_tool_tip_popup {
  position: fixed;
  background: var(--colorBoxPopUpInputBg);
  z-index: 9;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: var(--textColor);
}
.info_btn {
  color: var(--searchColor);
}
.info_btn:hover {
  color: var(--activeTextColor);
}
.info_btn:hover + .info_popup {
  display: block;
}
.info_popup {
  background: var(--colorBoxPopUpInputBg);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: var(--textColor);
}
.togglebtn {
  font-size: 11px;
  color: var(--colorBoxHeadingText);
}
.graph_line_indicator_div {
  height: 20px;
}
.graph_line_indicator_div .hdng {
  font-size: calc(0.3vw + 0.3vh + 0.35vmin);
}
.selectionCntnr label {
  font-weight: bold;
  text-transform: uppercase;
  color: var(--colorBoxHeadingText);
}
.sidebar_cntr {
  background: var(--sidebarbg) !important;
}
.unit_select_treep_speed select{
  width: 100%;
  background: var(--navBar2Bg);
  color: rgb(255, 255, 255);
  border-color: rgb(255, 255, 255);
  border-width: 1px !important;
  border-top: 1px solid !important;
  border-radius: 0.25rem;
}
.unit_select_treep_speed_realtime select{
  width: 100%;
  background: var(--navBar2Bg);
  color: rgb(255, 255, 255);
  border-color: rgb(255, 255, 255);
  border-width: 1px !important;
  border-top: 1px solid !important;
  border-radius: 0.25rem;
  height: 22px !important;
  padding: 0px 13px !important;

}
.unit_select_treep_speed{
  width: 76px;
}
.mud_weight_value{
  font-size: 8px !important;
  letter-spacing: 0.5px;
  font-weight: 500 !important;
}
.swaborsurge select{
  background-repeat: no-repeat;
  background-position-x: right;
}
</style>
